<template>
  <div class="prevMoves" v-if="room.cycleMoves.length > 0">
    История круга:
    <ol>
      <li v-for="move in reversedCycleMoves"
          :key="move.id" :class="{current: room.move && move.id === room.move.id}">
        <span :class="{self: move.explainingUserId === userId}">{{ users[move.explainingUserId].name }}</span>
        &rarr;
        <span :class="{self: move.guessingUserId === userId}">{{ users[move.guessingUserId].name }}</span>:
        {{ move.points }}
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "RoomPrevMoves",
  props: ['room', 'users', 'userId'],
  computed: {
    reversedCycleMoves: function () {
      return this.room.cycleMoves.slice().reverse();
    }
  }
}
</script>

<style scoped>
.prevMoves {
  margin-top: 5rem;
}

span {
  padding: 0.25rem;
}

.self {
  background-color: var(--highlight-color);
}

.current {
  font-weight: bold;
}
</style>
