export function hashCode(s) {
    let hash = 0, i = 0, len = s.length;
    while (i < len) {
        hash = ((hash << 5) - hash + s.charCodeAt(i++)) << 0;
    }
    return hash;
}

export function uid() {
    return Math.random().toString(36).substring(2, 5) + "_"
        + new Date().getTime() + "_"
        + Math.random().toString(36).substring(2, 10)
        + Math.abs(hashCode(window.performance.now().toString())).toString(36)
        + Math.random().toString(36).substring(2, 10);
}

export function transportUid() {
    return 't' + Math.random().toString(36).substring(2, 8)
        + (new Date().getTime().toString()).slice(-4)
        + Math.abs(hashCode(window.performance.now().toString())).toString(36).slice(-4);
}

export function normalize(s) {
    if (s === "0") {
        return "ноль";
    }
    if (s === "1") {
        return "один";
    }
    if (s === "2") {
        return "два";
    }
    if (s === "3") {
        return "три";
    }
    if (s === "4") {
        return "четыре";
    }
    if (s === "5") {
        return "пять";
    }
    if (s === "6") {
        return "шесть";
    }
    if (s === "7") {
        return "семь";
    }
    if (s === "8") {
        return "восемь";
    }
    if (s === "9") {
        return "девять";
    }

    const t = s.toLowerCase()
        .replace(/[^a-zа-яё]/gi, '')
        .replace(/\s+/gi, ', ')
        .replace(/ё/g, "е");
    let result = "";
    for (let i = 0; i < t.length; i++) {
        if (i === 0 || t.charAt(i) !== t.charAt(i - 1)) {
            result += t.charAt(i);
        }
    }
    return result;
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function toShortUserName(name) {
    if (!name || name.length < 20) {
        return name;
    } else {
        return name.substr(0, 8) + "…" + name.slice(-8);
    }
}

export function toDictionaryName(dictionary) {
    if (dictionary.toString() === "KIDS") {
        return "детский";
    } else if (dictionary.toString() === "EASY") {
        return "простой";
    } else if (dictionary.toString() === "MEDIUM") {
        return "средний";
    } else if (dictionary.toString() === "HARD") {
        return "сложный";
    } else if (dictionary.toString() === "CANDYBOBER") {
        return "кандибобер";
    } else if (dictionary.toString() === "ENGLISH") {
        return "английский";
    } else if (dictionary.toString() === "CUSTOM") {
        return "кастомный"
    }
}

export function setDarkMode(darkMode) {
    localStorage.setItem("darkMode", "" + darkMode);
    if (darkMode) {
        document.getElementById("html").className = 'dark';
    } else {
        document.getElementById("html").className = '';
    }
}

export function isDarkMode() {
    console.log("localStorage.getItem(\"darkMode\")=" + localStorage.getItem("darkMode"));
    return localStorage.getItem("darkMode") === "true";
}

export function adjustDarkMode() {
    setDarkMode(isDarkMode());
}

const base64abc = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
    "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
    "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"
];

/*
// This constant can also be computed with the following algorithm:
const l = 256, base64codes = new Uint8Array(l);
for (let i = 0; i < l; ++i) {
	base64codes[i] = 255; // invalid character
}
base64abc.forEach((char, index) => {
	base64codes[char.charCodeAt(0)] = index;
});
base64codes["=".charCodeAt(0)] = 0; // ignored anyway, so we just need to prevent an error
*/
const base64codes = [
    255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
    255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
    255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255, 255, 63,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255,
    255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 255, 255, 255, 255, 255,
    255, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51
];

function getBase64Code(charCode) {
    if (charCode >= base64codes.length) {
        throw new Error("Unable to parse base64 string.");
    }
    const code = base64codes[charCode];
    if (code === 255) {
        throw new Error("Unable to parse base64 string.");
    }
    return code;
}

export function bytesToBase64(bytes) {
    let result = '', i, l = bytes.length;
    for (i = 2; i < l; i += 3) {
        result += base64abc[bytes[i - 2] >> 2];
        result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
        result += base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
        result += base64abc[bytes[i] & 0x3F];
    }
    if (i === l + 1) { // 1 octet yet to write
        result += base64abc[bytes[i - 2] >> 2];
        result += base64abc[(bytes[i - 2] & 0x03) << 4];
        result += "==";
    }
    if (i === l) { // 2 octets yet to write
        result += base64abc[bytes[i - 2] >> 2];
        result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
        result += base64abc[(bytes[i - 1] & 0x0F) << 2];
        result += "=";
    }
    return result;
}

export function base64ToBytes(str) {
    if (str.length % 4 !== 0) {
        throw new Error("Unable to parse base64 string.");
    }
    const index = str.indexOf("=");
    if (index !== -1 && index < str.length - 2) {
        throw new Error("Unable to parse base64 string.");
    }
    let missingOctets = str.endsWith("==") ? 2 : str.endsWith("=") ? 1 : 0,
        n = str.length,
        result = new Uint8Array(3 * (n / 4)),
        buffer;
    for (let i = 0, j = 0; i < n; i += 4, j += 3) {
        buffer =
            getBase64Code(str.charCodeAt(i)) << 18 |
            getBase64Code(str.charCodeAt(i + 1)) << 12 |
            getBase64Code(str.charCodeAt(i + 2)) << 6 |
            getBase64Code(str.charCodeAt(i + 3));
        result[j] = buffer >> 16;
        result[j + 1] = (buffer >> 8) & 0xFF;
        result[j + 2] = buffer & 0xFF;
    }
    return result.subarray(0, result.length - missingOctets);
}

export function base64encode(str, encoder = new TextEncoder()) {
    return bytesToBase64(encoder.encode(str));
}

export function base64decode(str, decoder = new TextDecoder()) {
    return decoder.decode(base64ToBytes(str));
}
