<template>
  <div v-if="!room.move">
    <div v-if="room.nextPairs.length > 0">
      <RoomPlayerPair :explaining-user-name="users[room.nextPairs[0].explainingUserId].name"
                      :guessing-user-name="users[room.nextPairs[0].guessingUserId].name"/>
      <div class="word await-game-status">Ждём старт хода</div>
      <div class="secondsLeft" style="opacity: 0; visibility: hidden">25</div>
    </div>
    <div v-else>
      <RoomPlayerPair explaining-user-name="?"
                      guessing-user-name="?"/>
      <div class="word await-game-status">
        Начните игру, когда в комнате будут все игроки
      </div>
    </div>
  </div>
  <div v-else>
    <RoomPlayerPair :explaining-user-name="users[room.move.explainingUserId].name"
                    :guessing-user-name="users[room.move.guessingUserId].name"/>

    <div v-if="room.move.explainingUserId === userId" :class="{word: true,
    spoken: room.move.spoken,
    timeout: isTimeout}">
      {{ room.move.word }}
      <div class="timeoutNote" v-if="isTimeout">Время вышло!</div>
      <div ref="extraGuessingPeriodBar" class="extraGuessingPeriodBar"
           :style="{width: extraGuessingPeriodBarPercent + '%'}"></div>
    </div>

    <div v-else :class="{word: true, skipRecently, correctAnswerRecently, timeout: isTimeout}">
      <div class="explainedGuessedCount">
        {{ room.move.points }}
        <div class="timeoutNote" v-if="isTimeout">Время вышло!</div>
      </div>
      <div ref="extraGuessingPeriodBar" class="extraGuessingPeriodBar"
           :style="{width: extraGuessingPeriodBarPercent + '%'}"></div>
    </div>

    <div class="secondsLeft">{{ room.move.secondsLeft }}</div>
  </div>
</template>

<script>
import RoomPlayerPair from "@/components/RoomPlayerPair.vue";
import * as util from "@/util";

export default {
  name: "RoomStatus",
  components: {RoomPlayerPair},
  props: ['room', 'users', 'userId', 'skipRecently', 'correctAnswerRecently'],

  data: function () {
    return {
      extraGuessingPeriodBarPercent: 0
    };
  },

  methods: {
    toShortUserName(name) {
      return util.toShortUserName(name);
    },

    startExtraGuessingPeriodBar() {
      this.extraGuessingPeriodBarPercent = 100;
      const startTimeMillis = Date.now();
      const interval = window.setInterval(() => {
        const currentTimeMillis = Date.now();
        this.extraGuessingPeriodBarPercent = Math.max(0, 100.0 - (currentTimeMillis
            - startTimeMillis) * 100.0 / this.room.overtime / 1000.0);
      }, 10);
      window.setTimeout(() => {
        window.clearInterval(interval);
      }, this.room.overtime * 2000);
    }
  },

  beforeMount() {
    this.$root.$on("moveEndedConfirmed", () => {
      this.extraGuessingPeriodBarPercent = 0;
      if (this.room.overtime !== 0) {
        this.startExtraGuessingPeriodBar();
      }
    });
  },

  computed: {
    isTimeout: function () {
      return (this.room.move.secondsLeft <= 0 && this.room.move.explainingUserId === this.userId)
          || this.room.move.timeoutConfirmed;
    }
  }
}
</script>

<style scoped>
.secondsLeft {
  font-size: 4.2rem;
  text-align: center;
  margin: 0.25rem 0 3rem 0;
  padding: 0.25rem;
}

.correctAnswerRecently {
  color: #2E7D32 !important;
}

.skipRecently {
  color: #BF360C !important;
}

.timeout {
  background-color: var(--negative-color);
}

.timeoutNote {
  font-size: var(--smaller-font-size);
  font-weight: bold;
}

.extraGuessingPeriodBar {
  background-color: var(--success-color);
  height: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
}
</style>
