<template>
  <!--suppress HtmlUnknownTag -->
  <body id="app">
  <div class="popup-overlay"
       v-bind:style="{ display: this.overlay ? 'block' : 'none' }"></div>
  <AppHeader :userId="this.userId" :users="this.users" :rooms="this.rooms" :room-id="this.roomId"
             :person="this.person"/>

  <div v-if="this.room && this.room.saved" class="infobar">
    <i class="fa-solid fa-floppy-disk"></i> Комната была сохранена
  </div>

  <main>
    <div class="speech-recognition-word fabox"
         v-bind:class="{ running: this.speechRecognition.running, correctWord: this.speechRecognition.correctWord && (!move || room.move.guessingUserId !== this.userId)}">
      <a href="#" @click="onPlayRecording"><i class="fas fa-microphone" id="microphone"></i></a>
      <div v-if="this.microphoneClicked" class="microphone-clicked" style="text-align: left; z-index: 1000;">
        <div id="microphoneBlock" ref="microphoneBlock" style="clear: both">
          <ul>
            <li v-for="recording in this.sortedRecordings" :key="recording.timestamp">
              <a :href="recording.href" class="playRecordingLink" target="_blank"><i
                  class="fas fa-play no-float"></i></a>
              &nbsp;
              <a :href="recording.href" class="saveRecordingLink"
                 :download="recording.timestamp.replaceAll(':', '-').replaceAll(' ', '-') + '.wav'"><i
                  class="fas fa-download no-float"></i></a>
              &nbsp;
              <span class="timestamp">{{ recording.timestamp }} ({{ recording.duration }} сек.)</span>
            </li>
          </ul>
        </div>
      </div>
      <span v-else>
      {{ this.speechRecognition.word }}
      </span>
    </div>
    <Room v-if="this
                && this.roomId && this.rooms && this.rooms[this.roomId]
                && this.userId && this.users && this.users[this.userId]
                && this.users[this.userId].roomId === this.roomId
                && !this.rooms[this.roomId].edit"
          :room="this.rooms[this.roomId]"
          :userId="this.userId"
          :users="this.users"
          :messages="this.messages"
          :overlay="this.overlay"
          :connected-user-ids="this.connectedUserIds"
          :person="this.person"/>
    <Enter v-else
           :roomId="this.roomId" :userId="this.userId" :rooms="this.rooms" :users="this.users" :person="this.person"/>
  </main>
  <div v-if="pingMillis > 0" class="ping">{{ pingMillis }} ms</div>
  </body>
</template>

<script>
import {Transport} from "./transport";
import {SpeechRecognizer} from "./SpeechRecognizer";
import {Sounds} from "./Sounds";
import {uid, normalize, getParameterByName, adjustDarkMode} from "./util";

import AppHeader from "./components/AppHeader";
import Enter from "./components/Enter";
import Room from "./components/Room";
import axios from "axios";

export default {
  name: 'App',
  data: function () {
    return this.$root.$data;
  },
  components: {
    AppHeader,
    Enter,
    Room
  }, beforeDestroy() {
    const events = [
      "onComplain",
      "onServerEvent",
      "insertRoom",
      "editRoom",
      "skipMove",
      "renameUser",
      "insertUser",
      "kickUser",
      "onLogin",
      "deleteUser",
      "deleteMove",
      "resetGame",
      "shufflePlayers",
      "saveGame",
      "setRoomEdit",
      "setRoomMoveExplainingUserId",
      "setRoomMoveGuessingUserId",
      "startMove",
      "startGame",
      "onCorrectAnswer",
      "onSpeechCorrectAnswer",
      "onNoAnswer",
      "onSkip",
      "onInterruptMove",
      "addCustomWords",
      "removeCustomWords",
      "changeCounts",
      "setUserGuest"
    ];
    events.forEach(e => this.$root.$off(e));
  },

  computed: {
    sortedRecordings() {
      return this.recordings.slice().sort((a, b) => {
        return b.timestamp.localeCompare(a.timestamp);
      });
    },

    pingMillis: function () {
      if (this.pings.length === 0) {
        return 0;
      } else {
        return Math.round(this.pings.reduce((partialSum, a) => partialSum + a, 0) / this.pings.length);
      }
    },

    room: function () {
      return this.rooms && this.roomId && this.rooms[this.roomId] ? this.rooms[this.roomId] : null;
    },

    move: function () {
      return this.rooms && this.roomId && this.rooms[this.roomId] && this.rooms[this.roomId].move
          ? this.rooms[this.roomId].move : null;
    }
  },

  mounted() {
    this.$root.$on("onServerEventMoveStarted", () => {
      SpeechRecognizer.resetRecording();
      this.microphoneClicked = false;
      this.recordingCount = SpeechRecognizer.getRecordingCount();
      console.log("this.recordingCount=" + this.recordingCount);
    });

    this.$root.$on("onComplainWord", (word, direction) => {
      this.send({
        action: "onComplainWord",
        roomId: this.roomId,
        userId: this.userId,
        word,
        direction
      });
    });

    this.$root.$on("onServerEvent", (data) => {
      if (data.name === "MOVE_STARTED") {
        if (this.room && this.room.id === data.roomId) {
          this.$root.$emit("onServerEventMoveStarted");
        }
      } else if (data.name === "REPLACE_USER_ID") {
        if (this.userId === data.fromUserId) {
          localStorage.setItem("userId", data.toUserId);
          this.userId = data.toUserId;
        }
      } else if (data.name === "CORRECT_ANSWER") {
        if (this.userId
            && (this.userId === data.move.explainingUserId || this.userId === data.move.guessingUserId)) {
          Sounds.playCorrectAnswer();
        }
        this.$root.$emit("onServerEventCorrectAnswer", data.move);
      } else if (data.name === "SKIP") {
        if (this.userId
            && (this.userId === data.move.explainingUserId || this.userId === data.move.guessingUserId)) {
          navigator.vibrate([500]);
          Sounds.playViolation();
        }
        this.$root.$emit("onServerEventSkip", data.move);
      } else if (data.name === "BEFORE_MOVE_ENDS" && this.userId === data.userId) {
        if (this.move && this.move.explainingUserId === this.userId) {
          this.send({
            action: "beforeMoveEndsConfirmed",
            roomId: this.roomId,
            userId: this.userId,
            moveId: this.move.id
          });
        }
        Sounds.playBeforeMoveEnds();
      } else if (data.name === "MOVE_ENDED" && this.userId === data.userId) {
        if (this.move && this.move.explainingUserId === this.userId) {
          this.send({
            action: "moveEndedConfirmed",
            roomId: this.roomId,
            userId: this.userId,
            moveId: this.move.id
          });
        }
        navigator.vibrate([1000]);
        Sounds.playMoveEnded();
      } else if (this.move && data.name === "MOVE_ENDED_CONFIRMED" && this.move.id === data.moveId) {
        this.$root.$emit("moveEndedConfirmed");
      }
    });

    this.$root.$on("insertRoom", (moveDurationSeconds, gameCycleCount, dictionaries, overtime, rated) => {
      const roomId = uid();
      this.send({
        action: "insertRoom",
        roomId,
        moveDurationSeconds,
        gameCycleCount,
        dictionaries,
        overtime,
        rated
      });
      localStorage.setItem("roomId", roomId);
      this.roomId = roomId;
      let hasEnglish = false;
      for (const dictionary of dictionaries) {
        if (dictionary.indexOf("ENG") >= 0) {
          hasEnglish = true;
        }
      }
      if (hasEnglish) {
        SpeechRecognizer.setLang("en-US");
      } else {
        SpeechRecognizer.setLang("ru-RU");
      }
    });

    this.$root.$on("editRoom", (roomId, userId, moveDurationSeconds, gameCycleCount, dictionaries,
                                overtime, useChannelName, rated) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      this.send({
        action: "editRoom",
        roomId,
        userId,
        moveDurationSeconds,
        gameCycleCount,
        dictionaries,
        overtime,
        useChannelName,
        rated
      });
    });

    this.$root.$on("kickUser", (userId) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      if (this.userId) {
        if (!this.rooms[this.roomId].userIds.includes(userId)) {
          alert("Участник уже удалён из комнаты");
        } else if (this.rooms[this.roomId].move) {
          alert("Невозможно удалить пока идёт ход");
        } else if (confirm("Вы уверены, что хотите удалить из комнаты участника " + this.users[userId].name + "?")) {
          this.send({action: "deleteUser", roomId: this.roomId, userId: this.userId, targetUserId: userId});
        }
      }
    });

    this.$root.$on("addCustomWords", (words) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      this.send({
        action: "addCustomWords",
        roomId: this.roomId,
        userId: this.userId,
        words: words
      });
    });

    this.$root.$on("removeCustomWords", (words) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      this.send({
        action: "removeCustomWords",
        roomId: this.roomId,
        userId: this.userId,
        words: words
      });
    });

    this.$root.$on("changeCounts", (explainedChangeCounts, guessedChangeCounts) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      this.send({
        action: "changeCounts",
        explainedChangeCountsJson: JSON.stringify(explainedChangeCounts),
        guessedChangeCountsJson: JSON.stringify(guessedChangeCounts),
        roomId: this.roomId,
        userId: this.userId
      });
    });

    this.$root.$on("setOverlay", (overlay) => {
      this.overlay = overlay;
    });

    this.$root.$on("ping", () => {
      if (this.userId) {
        this.send({
          action: "ping",
          userId: this.userId,
          requestTimeMillis: this.timestamp(),
          transportUid: Transport.uid
        }, "/topic/server");
      }
    });

    this.$root.$on("insertUser", (roomId, userName, usePerson) => {
      const userId = uid();
      let personJson = usePerson && this.person ? JSON.stringify(this.person) : "";
      this.send({action: "insertUser", roomId, userId, userName, person: personJson});
      localStorage.setItem("userId", userId);
      this.userId = userId;
    });

    this.$root.$on("renameUser", (roomId, userId, userName) => {
      this.send({action: "renameUser", roomId, userId, userName});
    });

    this.$root.$on("deleteUser", () => {
      if (this.rooms && this.roomId && this.rooms[this.roomId].move.word) {
        alert("Невозможно выйти пока идёт ход");
      } else if (confirm("Вы действительно хотите выйти (удалиться) из комнаты? Все ваши ходы будут безвозвратно удалены из игры.")) {
        if (this.userId) {
          this.send({action: "deleteUser", userId: this.userId, targetUserId: this.userId});
        }
        this.roomId = null;
        localStorage.removeItem("roomId");
        this.userId = null;
        localStorage.removeItem("userId");
        location.href = "/";
      }
    });

    this.$root.$on("deleteMove", (moveInfo) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      const explainingUser = this.users[moveInfo.explainingUserId];
      const guessingUser = this.users[moveInfo.guessingUserId];
      if (explainingUser && guessingUser && confirm("Вы действительно хотите удалить ход "
          + moveInfo.index + " (" + explainingUser.name + " → " + guessingUser.name + "), счёт хода: "
          + moveInfo.points
          + "? В этом случае ход надо будет переиграть.")) {
        this.send({
          action: "deleteMove",
          roomId: this.roomId,
          userId: this.userId,
          moveId: moveInfo.moveId
        });
      }
    });

    this.$root.$on("skipMove", (explainingUser, guessingUser) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      this.send({
        action: "skipMove",
        roomId: this.roomId,
        userId: this.userId,
        explainingUserId: explainingUser.id,
        guessingUserId: guessingUser.id
      });
    });

    this.$root.$on("editMove", (moveInfo) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      const explainingUser = this.users[moveInfo.explainingUserId];
      const guessingUser = this.users[moveInfo.guessingUserId];
      if (explainingUser && guessingUser && confirm("Вы действительно хотите отредактировать ход "
          + moveInfo.index + " (" + explainingUser.name + " → " + guessingUser.name + "), счёт хода?")) {
        this.send({
          action: "editMove",
          roomId: this.roomId,
          userId: this.userId,
          moveId: moveInfo.moveId
        });
      }
    });

    this.$root.$on("setUserGuest", (targetUserId, guest) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      if (this.users[targetUserId] && confirm("Вы действительно хотите сделать гостя "
          + this.users[targetUserId].name + " официальным участником?")) {
        this.send({
          action: "setUserGuest",
          roomId: this.roomId,
          userId: this.userId,
          targetUserId,
          guest
        });
      }
    });

    this.$root.$on("setUserHidden", (targetUserId, hidden) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      if (this.users[targetUserId] && confirm(hidden ? "Вы действительно хотите скрыть участника "
          + this.users[targetUserId].name + "?" : "Вы действительно хотите отобразить участника "
          + this.users[targetUserId].name + "?")) {
        this.send({
          action: "setUserHidden",
          roomId: this.roomId,
          userId: this.userId,
          targetUserId,
          hidden
        });
      }
    });

    this.$root.$on("resetGame", () => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      if (confirm("Вы действительно хотите сбросить счёт и начать новую игру?")) {
        this.send({action: "resetGame", roomId: this.roomId, userId: this.userId});
      }
    });

    this.$root.$on("shufflePlayers", (force) => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      const message = force
          ? "Вы действительно хотите ПЕРЕМЕШАТЬ игроков (форсированный режим)?"
          : "Вы действительно хотите ПЕРЕМЕШАТЬ игроков?";

      if (confirm(message)) {
        this.send({action: "shufflePlayers", roomId: this.roomId, userId: this.userId, force});
      }
    });

    this.$root.$on("saveGame", () => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      const room = this.rooms[this.roomId];
      if (room.skippedMoves.length > 0) {
        let list = "";
        for (const skippedMove of room.skippedMoves) {
          let explainedUser = this.users[skippedMove.explainingUserId];
          let guessedUser = this.users[skippedMove.guessingUserId];
          list += "  • " + explainedUser.name + " → " + guessedUser.name + "\n";
        }
        if (confirm("Были пропущены ходы:\n\n" + list + "\nВы уверены, что хотите сохранить игру?")) {
          if (confirm("Вы в самом деле хотите сохранить игру, в которой БЫЛИ ПРОПУЩЕНЫ ХОДЫ?")) {
            this.send({action: "saveGame", roomId: this.roomId, userId: this.userId});
            alert("Игра сохранена на сайте")
          }
        }
      } else {
        this.send({action: "saveGame", roomId: this.roomId, userId: this.userId});
        alert("Игра сохранена на сайте")
      }
    });

    this.$root.$on("setRoomEdit", (edit) => {
      if (edit && (this.handleGuest() || this.handleMove())) {
        return;
      }

      this.send({action: "setRoomEdit", roomId: this.roomId, userId: this.userId, edit});
    });

    this.$root.$on("setRoomMoveExplainingUserId", () => {
      if (this.userId && this.userId !== this.rooms[this.roomId].move.guessingUserId) {
        this.send({action: "setRoomMoveExplainingUserId", userId: this.userId});
      }
    });

    this.$root.$on("setRoomMoveGuessingUserId", () => {
      if (this.userId && this.userId !== this.rooms[this.roomId].move.explainingUserId) {
        this.send({action: "setRoomMoveGuessingUserId", userId: this.userId});
      }
    });

    this.$root.$on("startMove", () => {
      if (this.move) {
        return;
      }

      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      if (this.rooms[this.roomId].nextPairs
          && this.userId === this.rooms[this.roomId].nextPairs[0].explainingUserId) {
        this.send({action: "startMove", roomId: this.roomId, userId: this.userId});
      }
    });

    this.$root.$on("startGame", () => {
      if (this.handleGuest() || this.handleMove()) {
        return;
      }

      this.send({action: "startGame", roomId: this.roomId, userId: this.userId});
    });

    this.$root.$on("onCorrectAnswer", () => {
      if (this.handleGuest()) {
        return;
      }

      if (this.userId === this.rooms[this.roomId].move.explainingUserId) {
        this.send({
          action: "onCorrectAnswer", userId: this.userId,
          moveId: this.rooms[this.roomId].move.id,
          word: this.rooms[this.roomId].move.word
        });
      }
    });

    this.$root.$on("onSpeechCorrectAnswer", (spokenWord) => {
      if (this.userId && this.userId !== this.rooms[this.roomId].move.explainingUserId) {
        this.send({action: "onSpeechCorrectAnswer", roomId: this.roomId, userId: this.userId, spokenWord});
      }
    });

    this.$root.$on("onNoAnswer", () => {
      if (this.handleGuest()) {
        return;
      }

      if (this.userId === this.rooms[this.roomId].move.explainingUserId
          || this.userId === this.rooms[this.roomId].move.guessingUserId) {
        this.send({
          action: "onNoAnswer",
          userId: this.userId,
          moveId: this.rooms[this.roomId].move.id,
          word: this.rooms[this.roomId].move.word
        });
      }
    });

    this.$root.$on("onSkip", () => {
      if (this.userId && this.userId === this.rooms[this.roomId].move.explainingUserId) {
        this.send({
          action: "onSkip",
          userId: this.userId,
          moveId: this.rooms[this.roomId].move.id,
          word: this.rooms[this.roomId].move.word
        });
      }
    });

    this.$root.$on("onInterruptMove", () => {
      if (this.userId && (this.userId === this.rooms[this.roomId].move.explainingUserId
          || this.userId === this.rooms[this.roomId].move.guessingUserId)) {
        this.send({
          action: "onInterruptMove",
          userId: this.userId,
          moveId: this.rooms[this.roomId].move.id
        });
      }
    });

    this.$root.$on("onConfirmChange", (moveId, word, count) => {
      if (this.handleGuest()) {
        return;
      }

      this.send({action: "onConfirmChange", moveId, userId: this.userId, word, count: Number.parseInt(count)});
    });

    this.$root.$on("onVoteChange", (moveId, word, vote) => {
      if (this.handleGuest()) {
        return;
      }

      this.send({action: "onVoteChange", moveId, userId: this.userId, word, vote: vote});
    });

    this.$root.$on("onConfirm", (count) => {
      if (this.handleGuest()) {
        return;
      }

      if (!this.rooms[this.roomId].move) {
        alert("Ход не найден");
        return;
      }

      this.send({action: "onConfirm", moveId: this.rooms[this.roomId].move.id, userId: this.userId, count});
    });
  }, created() {
    axios.get("https://hat42.org/data/user?action=findAuthenticated", {withCredentials: true})
        .then(response => {
          if (response.data.success) {
            this.person = response.data.user;
            console.log(JSON.stringify(this.person));
          }
        });

    if (getParameterByName("mike")) {
      this.person = JSON.parse("{\"uid\":\"pA1E1EwBoVu2hZuUxuWTHdkAAL19S4OrzUzCFeEBo7DiCHqz\",\"firstName\":\"Михаил\",\"lastName\":\"Мирзаянов\",\"shortName\":\"Майк\",\"nickname\":\"mikemirzayanov\",\"discordId\":\"691590282243997698\",\"admin\":true,\"id\":1001,\"updateTime\":\"2023-01-29 13:13:12\",\"creationTime\":\"2020-04-05 14:37:20\"}");
    } else if (getParameterByName("termit")) {
      this.person = JSON.parse("{\"uid\":\"czPZHYe6qnQ5JcygamPY3B6Hu9AdKM0zlwnNRC9YhbmwtRHI\",\"firstName\":\"Михаил\",\"lastName\":\"Иофе\",\"shortName\":\"Миша И\",\"nickname\":\"Termit\",\"discordId\":\"1068853155208638524\",\"admin\":false,\"level\":4,\"id\":1229,\"updateTime\":\"2023-04-11 21:37:23\",\"creationTime\":\"2020-06-20 19:49:01\"}");
    }

    console.log("Person: " + JSON.stringify(this.person));

    Transport.addUidHandler((response) => {
      if (response['action'] === "pong") {
        if (response.requestTimeMillis) {
          this.addPing(Math.round(this.timestamp() - response.requestTimeMillis + 0.5));
          this.connectedUserIds.splice(0);
          for (const uid of response['connectedUserIds']) {
            this.connectedUserIds.push(uid);
          }
        }
      }
    });

    Transport.addHandler((response) => {
      if (response['action'] === "onServerEvent") {
        this.$root.$emit("onServerEvent", response.data);
      } else if (response['action'] === "setTimestamp") {
        //console.log("Timestamp: " + request.data);
      } else if (response['action'] === "setData") {
        const data = response.data;

        if (data.rooms) {
          const messageIdsSets = {};
          if (this.rooms) {
            for (const room of Object.values(this.rooms)) {
              messageIdsSets[room.id] = new Set(room.messageIds);
            }
          }

          const ids = {};
          Object.keys(data.rooms).forEach(roomId => {
            if (!messageIdsSets[roomId]) {
              messageIdsSets[roomId] = new Set();
            }
            console.log("data.rooms[roomId].publishedMessageIds.length=" + data.rooms[roomId].publishedMessageIds.length);
            for (const id of data.rooms[roomId].publishedMessageIds) {
              messageIdsSets[roomId].add(id);
            }
            const messageIds = Array.from(messageIdsSets[roomId]);
            messageIds.sort();

            this.$set(this.rooms, roomId, data.rooms[roomId]);
            this.$set(this.rooms[roomId], "messageIds", messageIds);

            ids[roomId] = true;
          })

          const room = this.rooms[this.roomId];
          if (room && room.dictionaries) {
            let hasEnglish = false;
            for (const dictionary of room.dictionaries) {
              if (dictionary.indexOf("ENG") >= 0) {
                hasEnglish = true;
              }
            }
            if (hasEnglish) {
              SpeechRecognizer.setLang("en-US");
            } else {
              SpeechRecognizer.setLang("ru-RU");
            }
          }
        }

        if (data.users) {
          const ids = {};
          Object.keys(data.users).forEach(userId => {
            this.$set(this.users, userId, data.users[userId]);
            ids[userId] = true;
          })
        }

        if (data.messages) {
          console.log("data.messages.length=" + Object.keys(data.messages).length);
          const ids = {};
          Object.keys(data.messages).forEach(messageId => {
            this.$set(this.messages, messageId, data.messages[messageId]);
            ids[messageId] = true;
          })
        }
      }
    });

    let speechRecognizerLastWordTime = 0;

    if (!this.$isMobile()) {
      SpeechRecognizer.start();

      window.addEventListener('blur', () => {
        console.log("on window.blur: before SpeechRecognizer.stop()");
        SpeechRecognizer.stop();
        console.log("on window.blur: after SpeechRecognizer.stop()");
        this.speechRecognition.correctWord = false;
        this.speechRecognition.word = "Окно не в фокусе";
      });
      window.addEventListener('focus', () => {
        console.log("on window.focus");
        SpeechRecognizer.stopped = false;
        this.speechRecognition.word = "Окно в фокусе";
        setTimeout(() => SpeechRecognizer.start(), 1000);
      });
    }

    SpeechRecognizer.onWord((spokenWord) => {
      spokenWord = normalize(spokenWord);
      speechRecognizerLastWordTime = new Date().getTime();

      this.speechRecognition.running = true;
      let answered = false;
      let correct = false;

      if (this.rooms && this.roomId && this.users && this.userId) {
        const room = this.rooms[this.roomId];
        if (room && room.move) {
          const moveWord = normalize(room.move.word);
          if (moveWord === this.speechRecognition.word) {
            answered = true;
          } else if (moveWord === spokenWord) {
            correct = true;
          }
        }
      }

      if (correct) {
        this.speechRecognition.word = spokenWord;
        this.speechRecognition.correctWord = true;
        this.$root.$emit("onSpeechCorrectAnswer", spokenWord);
      } else if (!answered) {
        this.speechRecognition.word = spokenWord;
        this.speechRecognition.correctWord = false;
      }
    });

    SpeechRecognizer.onStart(() => {
      this.speechRecognition.running = true;
    });

    SpeechRecognizer.onEnd(() => {
      setTimeout(() => {
        if (new Date().getTime() - speechRecognizerLastWordTime >= 20000) {
          this.speechRecognition.running = false;
          this.speechRecognition.word = "Микрофон не активен";
          this.speechRecognition.correctWord = false;
        }
      }, 30000);
    });

    SpeechRecognizer.onSpeechStart(() => {
      this.speechRecognition.running = true;
    });

    SpeechRecognizer.onError(() => {
      setTimeout(() => {
        if (new Date().getTime() - speechRecognizerLastWordTime >= 20000) {
          this.speechRecognition.running = false;
          this.speechRecognition.word = "Микрофон не активен";
          this.speechRecognition.correctWord = false;
        }
      }, 30000);
    })
  }, beforeMount() {
    if (getParameterByName("start")) {
      localStorage.removeItem("roomId");
      localStorage.removeItem("userId");
      window.location.href = "/";
    } else {
      const parameterRoomId = getParameterByName("roomId");
      if (parameterRoomId) {
        this.roomId = parameterRoomId;
        localStorage.setItem("roomId", parameterRoomId);
        //console.log("set roomId from parameter: " + this.roomId);
        window.location.href = "/";
      } else {
        if (localStorage.getItem("roomId") && !this.roomId) {
          this.roomId = localStorage.getItem("roomId");
          //console.log("set roomId from localStorage: " + this.roomId);
        }
      }
    }

    if (localStorage.getItem("userId") && !this.userId) {
      this.userId = localStorage.getItem("userId");
      //console.log("set userId from localStorage: " + this.userId);
    }

    adjustDarkMode();

    if (this.roomId) {
      setTimeout(() => this.send({action: "chooseRoom", roomId: this.roomId}), 250);
      setTimeout(() => this.send({action: "chooseRoom", roomId: this.roomId}), 500);
      setTimeout(() => this.send({action: "chooseRoom", roomId: this.roomId}), 1000);
      setTimeout(() => this.send({action: "chooseRoom", roomId: this.roomId}), 2000);
    }

    this.$root.$emit("ping");
    setInterval(() => this.$root.$emit("ping"), 2000);
  }, methods: {
    onPlayRecording: function () {
      this.microphoneClicked = !this.microphoneClicked;
      setImmediate(() => {
        const microphoneBlock = this.$refs.microphoneBlock;
        if (microphoneBlock) {
          microphoneBlock.style.display = this.microphoneClicked ? "block" : "none";
          this.$set(this, "recordings", []);
          SpeechRecognizer.provideRecordings((recording) => {
            this.recordings.push(recording);
            console.log("recording_nxt: " + JSON.stringify(recording));
          });
        } else {
          setTimeout(() => {
            const microphoneBlock = this.$refs.microphoneBlock;
            if (microphoneBlock) {
              microphoneBlock.style.display = this.microphoneClicked ? "block" : "none";
              this.$set(this, "recordings", []);
              SpeechRecognizer.provideRecordings((recording) => {
                this.recordings.push(recording);
                console.log("recording_nxt: " + JSON.stringify(recording));
              });
            }
          }, 200);
        }
      });

      //
      // setTimeout(() => {
      //   const microphoneBlock = this.$refs.microphoneBlock;
      //   microphoneBlock.style.display = "none";
      //   this.recordingCount = SpeechRecognizer.getRecordingCount();
      //
      //   SpeechRecognizer.provideRecordings();
      //   microphoneBlock.style.display = "block";
      // }, 1000);
    },

    addPing: function (ping) {
      if (this.pings.length >= 10) {
        this.pings = this.pings.slice(-9);
      }
      this.pings.push(ping);
    },

    send: function (obj, destination) {
      Transport.send(JSON.stringify(obj), destination);
    },

    handleGuest: function () {
      if (!this.roomId || !this.userId || !this.users || !this.users[this.userId] || this.users[this.userId].guest) {
        alert("Гость не может выполнить это действие");
        return true;
      } else {
        return false;
      }
    },

    handleMove: function () {
      if (!this.rooms || !this.roomId || !this.rooms[this.roomId] || this.rooms[this.roomId].move) {
        console.trace();
        alert("Идёт ход, невозможно выполнить действие");
        return true;
      } else {
        return false;
      }
    },

    timestamp: function () {
      return (+new Date()) - 1672855135582;
    }
  }
}
</script>

<style scoped>
.speech-recognition-word {
  box-sizing: border-box;
  position: absolute;
  top: -5rem;
  right: 0;
  font-size: 1.4rem;
  text-align: center;
  border-radius: var(--border-radius);
  border: var(--border);
  margin: 0 0 1rem 0;
  padding: 0.75em;
  color: var(--caption-color);
  background-color: var(--background-color);
  width: min(30rem, 35%);
  z-index: 10000;
}

.correctWord {
  background-color: var(--success-color) !important;
}

main {
  margin-top: 6rem;
  position: relative;
}

.ping {
  width: 7rem;
  position: fixed;
  text-align: center;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  font-size: var(--small-font-size);
  background-color: var(--highlight-color);
}

.infobar {
  margin: 1rem 0;
  padding: 1rem;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--positive-color);
  text-align: center;
}

#microphoneBlock {
  font-size: var(--small-font-size);
  z-index: 10000;
}

.timestamp {
  font-family: Consolas, "Lucida Console", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}

.timedOut {
  /*background-color: var(--negative-color) !important;*/
}
</style>
