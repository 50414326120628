export default {
    microphoneClicked: false,
    recordings: [],
    overlay: false,
    rooms: {},
    roomId: null,
    users: {},
    userId: null,
    messages: {},
    pings: [],
    connectedUserIds: [],
    person: null,
    speechRecognition: {
        word: "Не активен",
        running: false,
        correctWord: false
    }
}
