<template>
  <div class="arrow">
    <div class="action-user" style="text-align: right">
      {{ toShortUserName(explainingUserName) }}
    </div>
    &rarr;
    <div class="action-user" style="text-align: left">
      {{ toShortUserName(guessingUserName) }}
    </div>
  </div>
</template>

<script>
import * as util from "@/util";

export default {
  name: "RoomPlayerPair",
  props: ['explainingUserName', 'guessingUserName'],

  methods: {
    toShortUserName(name) {
      return util.toShortUserName(name);
    }
  }
}
</script>

<style scoped>
.arrow {
  margin: 0;
  color: var(--caption-color);
  font-size: 3.8rem;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.action-user {
  width: 25%;
  text-align: center;
  margin: 0.25em 0;
  color: var(--black-color);
  font-weight: bold;
  font-size: 1.8rem;
  display: inline-block;
  position: relative;
  bottom: 4px;
}
</style>
