<template>
  <div>
    <div v-if="Object.keys(room.move.wordPoints).length === 0">
      Список слов хода пуст
    </div>
    <table>
      <tbody>
      <tr v-for="(w, index) in Object.keys(room.move.wordPoints)" :key="w">
        <td class="index">{{ index + 1 }}</td>
        <td class="word">
          <div>
            <template v-if="room.voteable">
              <div :class="{wordNone: actives[w] === 'NONE',
                            wordEasy: actives[w] === 'EASY',
                            wordMedium: actives[w] === 'MEDIUM',
                            wordHard: actives[w] === 'HARD'}">
                {{ w }}
              </div>

              <div class="voteTypes">
                <span @mouseover="handleMouseOver(w, 'простое')"
                      @mouseleave="handleMouseLeave(w)"
                      @click.prevent="handleClick(w, 'EASY')"
                      title="простое"
                      :class="{voteType: true, voteEasy: true, voteActive: actives[w] === 'EASY'}">E</span>
                <span @mouseover="handleMouseOver(w, 'среднее')"
                      @mouseleave="handleMouseLeave(w)"
                      @click.prevent="handleClick(w, 'MEDIUM')"
                      title="среднее"
                      :class="{voteType:true, voteMedium:true, voteActive: actives[w] === 'MEDIUM'}">M</span>
                <span @mouseover="handleMouseOver(w, 'сложное')"
                      @mouseleave="handleMouseLeave(w)"
                      @click.prevent="handleClick(w, 'HARD')"
                      title="сложное"
                      :class="{voteType: true, voteHard:true, voteActive: actives[w] === 'HARD'}">H</span>
                |
                <span @mouseover="handleMouseOver(w, 'плохое')"
                      @mouseleave="handleMouseLeave(w)"
                      @click.prevent="handleClick(w, 'BAD')"
                      title="плохое" :class="{voteType: true, voteBad: true, voteActive: actives[w] === 'BAD'}">B</span>
                <span @mouseover="handleMouseOver(w, 'сбросить')"
                      @mouseleave="handleMouseLeave(w)"
                      @click.prevent="handleClick(w, 'NONE')"
                      title="сбросить" class="voteType voteNone">&times;</span>
              </div>

              <div :class="{voteNote: true
                      , wordNone: notes.get(w) === 'проголосуйте'
                      , wordEasy: notes.get(w) === 'простое' || notes.get(w) === 'простое?'
                      , wordMedium: notes.get(w) === 'среднее' || notes.get(w) === 'среднее?'
                      , wordHard: notes.get(w) === 'сложное' || notes.get(w) === 'сложное?'
                      , wordBad: notes.get(w) === 'плохое' || notes.get(w) === 'плохое?'}">
                {{ notes.get(w) }}
              </div>
            </template>
            <template v-else>
              {{ w }}
            </template>
          </div>
        </td>
        <td :class="{'count': true, active: Number.parseFloat(p[0]) === room.move.wordPoints[w]}"
            v-for="p in points"
            :key="w + '_' + p[0]"
            @click.prevent="onConfirmClick(w, p[0])">
          <span :class="p[1]">{{ p[0] }}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="buttons">
      <button class="confirm-result-button" @click.prevent="onConfirm()">
        <span>Закончить ход</span>
        <span class="total">Суммарный балл: {{ total }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomConfirm",
  props: ['room'],
  data: function () {
    return {
      overNote: new Map(),
      ver: 0
    }
  },
  computed: {
    points: function () {
      // return [["-1", "#E57373"], ["-0.5", "#E57373"], ["0", "#000"], ["+0.5", "#81C784"], ["+1", "#81C784"]];
      return [["-1", "negative"], ["0", "zero"], ["+1", "positive"]];
    },
    total: function () {
      let sum = 0;
      Object.keys(this.room.move.wordPoints).forEach(key => sum += this.room.move.wordPoints[key]);
      return Math.max(sum, 0);
    },
    notes: function () {
      const result = new Map();
      for (const w of Object.keys(this.room.move.wordPoints)) {
        let note = "";

        if (this.overNote[w]) {
          note = this.overNote[w] + "?";
        } else if (!this.room.move.wordVote[w]
            || this.room.move.wordVote[w] === undefined
            || this.room.move.wordVote[w] === null
            || this.room.move.wordVote[w] === 'NONE') {
          note = "проголосуйте";
        } else if (this.room.move.wordVote[w] === 'EASY') {
          note = "простое";
        } else if (this.room.move.wordVote[w] === 'MEDIUM') {
          note = "среднее";
        } else if (this.room.move.wordVote[w] === 'HARD') {
          note = "сложное";
        } else if (this.room.move.wordVote[w] === 'BAD') {
          note = "плохое";
        }

        result.set(w, note);
      }

      result.set(this.ver, 1);
      return result;
    },
    actives: function () {
      const result = {};
      for (const w of Object.keys(this.room.move.wordPoints)) {
        let key = "";

        if (!this.room.move.wordVote[w]
            || this.room.move.wordVote[w] === undefined
            || this.room.move.wordVote[w] === null
            || this.room.move.wordVote[w] === 'NONE') {
          key = "NONE";
        } else if (this.room.move.wordVote[w] === 'EASY') {
          key = "EASY";
        } else if (this.room.move.wordVote[w] === 'MEDIUM') {
          key = "MEDIUM";
        } else if (this.room.move.wordVote[w] === 'HARD') {
          key = "HARD";
        } else if (this.room.move.wordVote[w] === 'BAD') {
          key = "BAD";
        }

        result[w] = key;
      }

      result[this.ver] = 1;
      return result;
    }
  },
  methods: {
    handleMouseOver: function (w, note) {
      console.log("over", w, note);
      this.$set(this.overNote, w, note);
      this.ver++;
    },

    handleMouseLeave: function (w) {
      console.log("leave", w);
      this.$delete(this.overNote, w);
      this.ver++;
    },

    handleClick: function (w, vote) {
      this.$root.$emit("onVoteChange", this.room.move.id, w, vote);
    },

    note: function (w) {
      console.log(w);

      if (this.overNote[w]) {
        return this.overNote[w];
      }

      if (this.room.move.wordVote[w]
          || this.room.move.wordVote[w] === undefined
          || this.room.move.wordVote[w] === null
          || this.room.move.wordVote[w] === 'NONE') {
        return "проголосуйте";
      }

      return "?";
    },

    onConfirmClick: function (word, count) {
      this.$root.$emit("onConfirmChange", this.room.move.id, word, count);
    },

    onVoteClick: function (word, vote) {
      this.$root.$emit("onVoteChange", this.room.move.id, word, vote);
    },

    onConfirm: function () {
      this.$root.$emit("onConfirm", this.total);
    }
  }
}
</script>

<style scoped>

.negative {
  color: var(--negative-color);
}

.zero {
  color: var(--zero-color);
}

.positive {
  color: var(--positive-color);
}

table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

table tr:last-child {
  border-bottom: none;
}

table tr {
  border-bottom: var(--border);
}

table td {
  padding: 1rem 1.5rem;
}

td.word {
  background-color: transparent;
  color: var(--caption-color);
  font-weight: bold;
}

td.index {
  width: 4rem;
  text-align: center;
}

td.count {
  width: 4rem;
  text-align: center;
  opacity: 0.5;
  cursor: pointer;
}

tr:hover, tr:hover td {
  background-color: var(--hover-row-color) !important;
}

td.count:hover {
  background-color: var(--hover-cell-color) !important;
}

td.active {
  opacity: 1 !important;
  /*background-color: #B3E5FC !important;*/
  background-color: var(--highlight-color) !important;
  font-weight: bold;
}

button {
  margin: 1em auto;
  color: black;
  font-size: 1.8rem;
  text-align: center;
  padding: 1em 2em;
  display: block;
  background-color: var(--success-color);
  border: 0.1rem solid ghostwhite;
  width: min(100%, 30rem);
  cursor: pointer;
}

.total {
  display: block;
  margin-top: 0.2rem;
  font-size: 1.2rem;
  color: var(--muted-color);
}

@media (max-width: 1024px) {
  table .word {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 800px) {
  table .word {
    font-size: 1.4rem !important;
  }

  .voteNone, .voteMinus, .votePlus {
    height: 2.5rem !important;
  }

  .voteNote {
    font-size: var(--smallest-font-size) !important;
  }

  .vote-icon {
    font-size: var(--small-font-size) !important;
  }
}

.buttons {
  margin-top: 4rem;
}

.vote {
  cursor: pointer;
  font-size: 1.4rem;
  display: inline-block;
  margin: 0 0.5rem;
  color: var(--minor-color);
  padding-bottom: 0.4rem;
}

.voteNone, .voteMinus, .votePlus {
  cursor: pointer;
}

.voteNone {
  color: var(--minor-color) !important;
}

.votePlus {
  color: var(--positive-color) !important;
}

.voteMinus {
  color: var(--negative-color) !important;
}

.voteNote {
  font-size: var(--small-font-size);
  margin-top: 0.2rem;
}

.vote-icon {
  font-size: var(--smaller-font-size);
  position: relative;
  bottom: 0.0rem;
}

.voteTypes {
  height: 4rem;
  font-size: 1.4rem;
  font-weight: bold;
}

.voteType {
  cursor: pointer;
  display: inline-block;
  margin: 0.5rem;
  color: var(--black-color);
  border: 4px solid var(--border-color);
  width: 4rem;
}

@media (max-width: 800px) {
  .word {
    padding: 0.5rem !important;
  }
  .voteTypes {
    height: 2.5rem;
  }

  .voteType {
    width: 1.8rem !important;
    margin: 0.5rem 1px;
    font-size: 0.8rem !important;
    border: 2px solid var(--border-color);
    line-height: 1.6rem;
  }

  .voteType:hover {
    border: 2px solid blue;
  }

  .voteNone {
    border: 2px solid transparent !important;
  }

  .voteActive {
    color: blue !important;
    border: 2px solid blue;
  }

  table td {
    padding: 0.5rem 0.75rem;
  }

  td.index {
    width: 3rem;
  }

  td.count {
    width: 3rem;
  }
}

.voteType:hover {
  border: 4px solid blue;
}

.voteEasy {
  background-color: var(--positive-color) !important;
}

.voteMedium {
  background-color: #FBC02D !important;
}

.voteHard {
  background-color: var(--negative-color) !important;
}

.voteBad {
  background-color: black !important;
  color: var(--white-color) !important;
}

.voteNone {
  background-color: transparent !important;
  border: 4px solid transparent !important;
  width: 0.25rem !important;
}

.voteActive {
  color: blue !important;
  border: 4px solid blue;
}

.wordNone {
  color: var(--minor-color) !important;
}

.wordEasy {
  color: var(--success-color) !important;
}

table tbody .word .wordMedium {
  color: var(--orange-color) !important;
}

.wordHard {
  color: var(--negative-color) !important;
}

</style>
