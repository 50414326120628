import { render, staticRenderFns } from "./Room.vue?vue&type=template&id=c2a0ed84&scoped=true&"
import script from "./Room.vue?vue&type=script&lang=js&"
export * from "./Room.vue?vue&type=script&lang=js&"
import style0 from "./Room.vue?vue&type=style&index=0&id=c2a0ed84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a0ed84",
  null
  
)

export default component.exports