<template>
  <div :class="{saved: room && room.saved}">
    <div v-for="u in roomUsers.filter(u_ => !u_.hidden)" :key="u.id" class="user"
         :class="{ spoken: room.move && room.move.guessedUserIds.includes(u.id) }">
      <div v-if="!user.guest" class="kick" @click.prevent="$root.$emit('kickUser', u.id)">&times;</div>
      <span :class="{name: true, connected: connectedUserIds.includes(u.id)}">
        <span v-if="room.channelName && !u.discordName" title="Нет привязки к Discord-аккаунту">
          <i class="noDiscordName fa fa-volume-xmark"></i>
        </span>
        <span v-if="u.person"><Person :person="u.person" :name="toShortUserName(u.name)"/></span>
        <span v-else v-text="toShortUserName(u.name)"></span>
      </span>
      <span v-if="u.guest" class="guest">
        гость
        <span v-if="!user.guest">
          &rarr;
          <a class="guest" href="#" @click.prevent="$root.$emit('setUserGuest', u.id, false)">в игроки</a>
          или
          <a class="guest" href="#" @click.prevent="$root.$emit('setUserHidden', u.id, true)">скрыть</a>
        </span>
      </span>
      <span class="counts">
        <span v-if="!u.guest">
          {{ u.explainedPoints }}+{{ u.guessedPoints }}=<span
            class="total-count">{{ u.explainedPoints + u.guessedPoints }}</span>
          <span v-if="u.advancedCount>0" class="advanced-count">
            (+{{ u.advancedCount }})
          </span>
        </span>
        <span v-else>
          <span v-if="u.advancedCount>0" class="advanced-count">
            (+{{ u.advancedCount }})
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import * as util from "@/util";
import Person from "@/components/Person.vue";

export default {
  name: "RoomUsers",
  components: {Person},
  props: ['room', 'roomUsers', 'user', 'connectedUserIds'],
  methods: {
    toShortUserName(name) {
      return util.toShortUserName(name);
    }
  }
}
</script>

<style scoped>
.user {
  border-radius: var(--border-radius);
  border: var(--border);
  margin: 0 0 1rem 0;
  padding: 0.75em;
  color: var(--caption-color);
  font-weight: bold;
  font-size: 1.4rem;
  background-color: var(--datatable-color);
  position: relative;
  display: flow-root;
}

.kick {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  font-size: 1.2rem;
  color: var(--muted-color);
  cursor: pointer;
}

.total-count {
  font-weight: bold;
  color: var(--black-color);
}

.advanced-count {
  color: var(--muted-color);
}

.counts {
  color: var(--muted-color);
  font-size: 1.2rem;
  float: right;
  position: relative;
  top: 4px;
}

.guest {
  font-size: 1.1rem;
  color: var(--muted-color);
}

.name {
  color: var(--muted-color);
}

.connected {
  color: var(--caption-color);
}

.noDiscordName {
  color: var(--error-color);
  margin-right: 0.25rem;
}

.saved .name {
  color: var(--positive-color);
}
</style>
