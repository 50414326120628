<template>
  <div v-if="room && room.dictionaries.includes('CUSTOM')" class="customDictionary">
    <div class="addCustomWords popup" v-bind:style="{ display: showAddCustomWords ? 'block' : 'none' }">
      <div class="close" @click.prevent="showAddCustomWords = false;$root.$emit('setOverlay', false);">&times;</div>
      <form @submit.prevent="onAddCustomWords()">
        <textarea ref="includeCustomWords" v-model="includeCustomWords"></textarea>
        <input class="button" type="submit" value="Добавить слова">
        <span v-text="countIncludeCustomWords"></span>
      </form>
    </div>

    <div class="removeCustomWords popup" v-bind:style="{ display: showRemoveCustomWords ? 'block' : 'none' }">
      <div class="close" @click.prevent="showRemoveCustomWords = false; $root.$emit('setOverlay', false);">&times;</div>
      <form @submit.prevent="onRemoveCustomWords()">
        <textarea ref="excludeCustomWords" v-model="excludeCustomWords"></textarea>
        <input class="button" type="submit" value="Удалить слова">
        <span v-text="countExcludeCustomWords"></span>
      </form>
    </div>

    <div>
      Осталось слов <span v-text="room.customWords.length"></span>
    </div>

    <a href="#" @click.prevent="onShowAddCustomWords()">добавить</a>
    <a href="#" @click.prevent="onShowRemoveCustomWords()">удалить</a>
  </div>
</template>

<script>
export default {
  name: "RoomCustomWords",
  props: ['room'],
  data: function () {
    return {
      showAddCustomWords: false,
      includeCustomWords: "",
      showRemoveCustomWords: false,
      excludeCustomWords: ""
    }
  },
  mounted() {
    this.$root.$on('closePopups', () => {
      this.showAddCustomWords = false;
      this.showRemoveCustomWords = false;
    });
  },
  computed: {
    countIncludeCustomWords: function () {
      return "слов: " + new Set(this.includeCustomWords.split(/\s+/).filter(value => value.length > 0)).size;
    },

    countExcludeCustomWords: function () {
      return "слов: " + new Set(this.excludeCustomWords.split(/\s+/).filter(value => value.length > 0)).size;
    }
  },
  methods: {
    onShowAddCustomWords: function () {
      this.showAddCustomWords = true;
      this.$root.$emit('setOverlay', true);
      setTimeout(() => this.$refs.includeCustomWords.focus(), 200);
    },

    onShowRemoveCustomWords: function () {
      this.showRemoveCustomWords = true;
      this.$root.$emit('setOverlay', true);
      setTimeout(() => this.$refs.excludeCustomWords.focus(), 200);
    },

    onAddCustomWords: function () {
      this.$root.$emit('addCustomWords', this.includeCustomWords);
      this.includeCustomWords = "";
      this.showAddCustomWords = false;
      this.$root.$emit('setOverlay', false);
    },

    onRemoveCustomWords: function () {
      this.$root.$emit('removeCustomWords', this.excludeCustomWords);
      this.excludeCustomWords = "";
      this.showRemoveCustomWords = false;
      this.$root.$emit('setOverlay', false);
    }
  }
}
</script>

<style scoped>
.customDictionary {
  border-radius: var(--border-radius);
  border: var(--border);
  color: var(--caption-color);
  cursor: pointer;
  font-size: var(--normal-font-size);
  margin: 0 0 1rem 0;
  padding: 0.75em;
  position: relative;
  overflow: auto;
}

.customDictionary div {
  color: var(--caption-color);
}

.customDictionary div span {
  float: right;
  color: black;
  text-align: right;
}

.customDictionary a {
  color: var(--muted-color);
  font-size: var(--smaller-font-size);
  float: right;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.addCustomWords, .removeCustomWords {
}

.addCustomWords form,
.removeCustomWords form {
  margin: 0;
  padding: 0;
}

.addCustomWords form span,
.removeCustomWords form span {
  display: inline-block;
  margin-left: 2rem;
  font-size: var(--normal-font-size);
  color: var(--muted-color);
}

.addCustomWords textarea,
.removeCustomWords textarea {
  width: 100%;
  height: 20rem;
  display: block;
}

.addCustomWords input,
.removeCustomWords input {
  margin-top: 1rem;
  padding: 0.25rem 1rem;
}
</style>