<!--suppress JSUnresolvedFunction -->
<template>
  <header :class="{saved: room && room.saved}">
    <a title="https://hat42.org" target="_blank" href="https://hat42.org/"><img src="../assets/img/logo.png"
                                                                                class="logo" alt="https://hat42.org"
                                                                                title="https://hat42.org"/></a>
    <div :class="{name: true}">
      <a title="https://hat42.org" target="_blank" href="https://hat42.org/">Шляпа 42</a>
      <div class="channelName" v-if="room && room.channelName">
        <i class="fa fa-headphones"></i>
        {{ room.channelName }}
        <template v-if="user">
          <!--          <i class="fa fa-arrow-right"></i>-->
          <!--          {{ user }}-->
          <i class="fa fa-arrow-right"></i>
          <span v-if="!user.discordName">
            <span v-if="user.discordSecret" class="sendToDiscordChat">
              Отправьте в Discord-чат этой комнаты сообщение <code>{{ user.discordSecret }}</code>
            </span>
            <span v-else>
              ???
            </span>
          </span>
          <span v-else>
            {{ user.discordName }}
          </span>
        </template>
      </div>
    </div>

    <span v-if="person" class="userName">
      <Person :person="person" show-nickname="true"/>
    </span>
    <span v-else-if="this.userId && this.users && this.users[this.userId]" class="userName"
          v-text="toShortUserName(this.users[this.userId].name)"/>

    <span class="gear"
          @click.prevent="onShowSettings()"><i
        class="fas fa-gear"></i></span>
    <div class="popup settings" v-bind:style="{ display: showSettings ? 'block' : 'none' }">
      <div class="close" @click.prevent="showSettings = false; $root.$emit('setOverlay', false);">&times;</div>

      <div class="title">Настройки</div>

      <section>
        <div class="note">Настройки громкости:</div>
        <div>
          <form>
            <div style="margin-bottom: 1rem" v-for="volume in volumes" :key="volume[1]">
              <input :id="'sound-volume-' + volume[1]" type="radio" name="soundVolume" :value="volume[0]"
                     :checked="volume[0] === getVolume"
                     @change.prevent="changeVolume(volume[0]);">
              <label class="radio" :for="'sound-volume-' + volume[1]">{{ volume[2] }}</label>
            </div>
          </form>
        </div>
      </section>

      <section v-if="users && userId && users[userId]">
        <div class="note"><label for="name">Имя:</label></div>
        <div>
          <form @submit.prevent="saveUserName()">
            <div style="margin-bottom: 1rem">
              <input id="userName" name="userName" v-model="userName"/>
              <input type="submit" id="saveUserName" name="saveUserName" value="Сохранить">
            </div>
            <div class="error">{{ error }}</div>
          </form>
        </div>
      </section>

      <section>
        <div class="note">Тёмная тема:</div>
        <div>
          <form>
            <div>
              <input ref="darkMode" type="checkbox" id="darkMode" name="darkMode" v-model="darkMode"
                     @change.prevent="onDarkModeChange"/>
              <label for="darkMode" id="darkModeLabel">использовать тёмную тему</label>
            </div>
          </form>
        </div>
      </section>

      <section>
        <input type="button" class="button" name="Закрыть" value="Закрыть"
               @click.prevent="showSettings = false; $root.$emit('setOverlay', false);"/>
      </section>
    </div>
  </header>
</template>

<script>
import {Sounds} from "@/Sounds";
import * as util from "@/util.js";
import Person from "@/components/Person.vue";

export default {
  name: "AppHeader",
  components: {Person},
  props: ['userId', 'users', 'rooms', 'roomId', 'person'],
  data: function () {
    return {
      showSettings: false,
      volumes: Sounds.getVolumes(),
      userName: "",
      error: "",
      darkMode: false
    }
  }, computed: {
    getVolume: function () {
      return localStorage.getItem("volume");
    },

    room: function () {
      if (this && this.rooms && this.roomId) {
        return this.rooms[this.roomId];
      } else {
        return null;
      }
    },

    user: function () {
      if (this && this.users && this.userId) {
        return this.users[this.userId];
      } else {
        return null;
      }
    }
  }, methods: {
    saveUserName: function () {
      if (!this.userName || this.userName.trim().length === 0) {
        this.error = "Введите имя";
        return;
      }

      if (this.userName.length > 24
          || this.userName.indexOf("&") >= 0
          || this.userName.indexOf("<") >= 0
          || this.userName.indexOf(">") >= 0
          || this.userName.indexOf("\"") >= 0
          || this.userName.indexOf("'") >= 0
          || this.userName.indexOf(";") >= 0) {
        this.error = "Имя слишком длинное или содержит один из служебных символов &<>;\"'";
      } else {
        const noSpacesThisUserName = this.userName.trim().replace(/\s+/g, '');
        for (const uid of this.rooms[this.roomId].userIds) {
          if (uid && uid !== this.userId && this.users[uid]) {
            const u = this.users[uid];
            const noSpacesName = u.name.trim().replace(/\s+/g, '');
            if (noSpacesName === noSpacesThisUserName) {
              this.error = "Имя уже используется";
              return;
            }
          }
        }

        this.error = "";
        this.$root.$emit("renameUser", this.roomId, this.userId, this.userName);
      }
    },

    onShowSettings: function () {
      this.showSettings = true;
      if (this.users && this.userId && this.users[this.userId]) {
        this.userName = this.users[this.userId].name;
      }
      this.darkMode = util.isDarkMode();
      this.$root.$emit('setOverlay', true);
    },

    toShortUserName: function (name) {
      return util.toShortUserName(name);
    },

    changeVolume: function (volumeString) {
      localStorage.setItem("volume", volumeString);
      Sounds.playCorrectAnswer();
    },

    onDarkModeChange: function () {
      util.setDarkMode(this.$refs.darkMode.checked);
    }
  }, mounted() {
    this.$root.$on("closePopups", () => this.showSettings = false);
  }
}
</script>

<style scoped>
header {
  color: var(--white-color);
  height: 6rem;
  background-color: #303F9F;
  width: auto;
  overflow: hidden;
}

.logo {
  margin: 1rem;
  height: 4rem;
}

.name {
  position: absolute;
  font-size: 2.4rem;
  left: 7rem;
  top: 1.7rem;
}

.userName {
  position: absolute;
  font-size: 2.4rem;
  right: 7rem;
  top: 1.7rem;
}

.gear {
  position: absolute;
  font-size: 2.4rem;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
}

#saveUserName {
  padding: 0 1rem;
  margin-left: 1rem;
}

#darkModeLabel {
  font-size: var(--normal-font-size);
  position: relative;
  bottom: 0.2rem;
  margin-left: 0.5rem;
}

.error {
  font-size: var(--small-font-size);
}

.channelName {
  font-size: var(--smaller-font-size);
}

.sendToDiscordChat {
  color: var(--error-color);
}

header a {
  text-decoration: none;
  color: var(--white-color);
}

.saved {
  background-color: var(--positive-color) !important;
}
</style>
