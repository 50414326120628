<template>
  <div class="nextMoves" v-if="room.nextPairs.length > 0">
    Оставшиеся ходы круга:
    <ol>
      <li v-for="pair in room.nextPairs"
          :key="pair.explainingUserId + ',' + pair.guessingUserId">
        <span :class="{self: pair.explainingUserId === userId}">{{
            users[pair.explainingUserId].name
          }}</span>
        &rarr;
        <span :class="{self: pair.guessingUserId === userId}">{{
            users[pair.guessingUserId].name
          }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "RoomNextMoves",
  props: ['room', 'users', 'userId'],
  methods: {
    get_class: function (id) {
      const ids = new Set();
      for (const p of this.room.nextPairs) {
        ids.add(p.explainingUserId);
        ids.add(p.guessingUserId);
      }
      const ids_ = [...ids];
      for (var i = 0; i < ids_.length; i++) {
        if (ids_[i] === id) {
          return "r" + i;
        }
      }
    }
  }
}
</script>

<style scoped>
.nextMoves {
  margin-top: 5rem;
}

span {
  padding: 0.25rem;
}

.self {
  background-color: var(--highlight-color);
}

.r0:hover {
  background-color: red;
}

.r1:hover {
  background-color: red;
}
</style>
