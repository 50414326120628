<template>
  <div v-if="(!roomId || !rooms || !(roomId in rooms))
      || edit" class="insert-room">
    <div class="welcome">
      <span v-if="edit">
      Измените настройки комнаты
      </span>
      <span v-else>
      Для начала игры надо создать комнату и выслать ссылку другим участникам
      </span>
    </div>
    <div class="form">
      <form @submit.prevent="handleRoom()">
        <div class="field">
          <label class="moveDurationSecondsLabel">
            <span>Длительность хода:</span>
            <select ref="moveDurationSeconds" class="moveDurationSeconds" v-model.number="moveDurationSeconds">
              <option value="3">3 секунды</option>
              <option value="5">5 секунд</option>
              <option value="10">10 секунд</option>
              <option value="15">15 секунд</option>
              <option value="20">20 секунд</option>
              <option value="25">25 секунд</option>
              <option value="30">30 секунд</option>
              <option value="35">35 секунд</option>
              <option value="40">40 секунд</option>
              <option value="45">45 секунд</option>
              <option value="50">50 секунд</option>
              <option value="55">55 секунд</option>
              <option value="60">60 секунд</option>
            </select>
          </label>
        </div>

        <div class="field">
          <label class="gameCycleCountLabel">
            <span>Количество кругов:</span>
            <select class="gameCycleCount" v-model.number="gameCycleCount">
              <option value="0">авто</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="-1">без ограничения</option>
            </select>
          </label>
        </div>

        <div class="field">
          <label class="dictionariesLabel">
            <span>Словарь:</span>
            <select class="dictionaries" v-model="dictionaries">
              <option value="KIDS">детский</option>
              <option value="EASY">простой</option>
              <option value="MEDIUM">средний</option>
              <option value="MEDIUM_EXT_07_2023">средний-доп-07-2023</option>
              <option value="HARD">сложный</option>
              <option value="CANDYBOBER">кандибобер</option>
              <option value="ENGLISH">английский</option>
              <option value="CUSTOM">добавим слова сами</option>
            </select>
          </label>
        </div>

        <div class="field">
          <label class="overtimeLabel">
            <span>Время на ответ после хода:</span>
            <select class="overtime" v-model="overtime">
              <option value="0">нет ограничения</option>
              <option value="1">1 секунда</option>
              <option value="2">2 секунды</option>
              <option value="3">3 секунды</option>
              <option value="4">4 секунды</option>
              <option value="5">5 секунд</option>
              <option value="6">6 секунд</option>
              <option value="7">7 секунд</option>
              <option value="8">8 секунд</option>
              <option value="9">9 секунд</option>
              <option value="10">10 секунд</option>
              <option value="11">11 секунд</option>
              <option value="12">12 секунд</option>
              <option value="13">13 секунд</option>
              <option value="14">14 секунд</option>
              <option value="15">15 секунд</option>
            </select>
          </label>
        </div>

        <div class="field">
          <label class="rated">
            <span>Рейтинг:</span>
            <input type="checkbox" name="rated" id="rated" v-model="rated">
            <span class="ratedNote">
              Учитывать игру в рейтинге
              на сайте <a href="https://hat42.org/">hat42.org</a>
              <span class="i" ref="ratingNote"
                    data-text="Игра добавляется в рейтинг, если в ней приняли участие не менее трёх участников с аккаунтом на сайте https://hat42.org/, игра длилась не менее 12 ходов, продолжительность хода составила от 20 до 60 секунд."
                    @click.prevent="showAlert">
                <i class="fa fa-question-circle"></i>
              </span>
            </span>
          </label>
        </div>

        <div v-if="room && room.channelName" class="field">
          <label class="useChannelName">
            <span>Discord-интеграция:</span>
            <input id="" type="checkbox" v-model="useChannelName"/>
            <small class="small">
              Использовать привязку к Discord-каналу &laquo;{{ room.channelName }}&raquo;
            </small>
          </label>
        </div>

        <div class="buttons">
          <template v-if="edit">
            <input style="margin: 0.5rem 2rem" type="submit" ref="edit" class="edit" value="Изменить комнату"/>
            <input style="margin: 0.5rem 2rem" type="button" ref="leave" class="leave" value="Отмена"
                   @click.prevent="$root.$emit('setRoomEdit', false)"/>
          </template>
          <input v-else type="submit" ref="create" class="create" value="Создать комнату"/>
<!--          <input type="button" value="Reset Recording" @click.prevent="onResetRecording"/>-->
        </div>

        <div class="note" v-if="gameCycleCount > 0">
          Ожидаемая продолжительность игры:

          <ul style="margin-bottom: 0">
            <li v-for="playerCount in [2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="playerCount">игроков: {{ playerCount }}
              &rarr;
              ходов: {{ gameCycleCount * playerCount * (playerCount - 1) }},
              минут: {{
                Math.round(gameCycleCount * playerCount * (playerCount - 1)
                    * (moveDurationSeconds + 25) / 60 / 5) * 5
              }}
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
  <div v-else class="insert-user">
    <div class="form">
      <form @submit.prevent="insertUser">
        <label class="welcome">
          Введите ваше имя
          <input ref="userName" class="userName" v-model="userName"/>
          <span v-if="usePerson && person" class="usePersonNote">@{{ person.nickname }}</span>
        </label>
        <div v-if="person" class="use-person">
          <div>
            <input type="checkbox" id="usePerson" v-model="usePerson"><label for="usePerson">Использовать
            @{{ person.nickname }}</label>
          </div>
        </div>
        <div class="buttons">
          <input style="margin: 0.5rem 2rem" type="submit" ref="create" class="create" value="Войти в комнату"/>
          <input style="margin: 0.5rem 2rem" type="button" ref="leave" class="leave" value="Покинуть комнату"
                 @click.prevent="leaveRoom"/>
          <div class="error" v-html="error"></div>
        </div>
      </form>
    </div>

    <div class="room">
      <div v-if="this.rooms[this.roomId].userIds.length === 0">
        Комната пока пуста
      </div>
      <div v-else>
        В комнате уже:
      </div>
      <ul>
        <template v-for="userId in this.rooms[this.roomId].userIds">
          <li  v-if="users[userId] && !users[userId].name.startsWith('hidden-')" :key="userId">
            {{ users[userId] ? users[userId].name : "?" }}
          </li>
        </template>
      </ul>
    </div>
    <footer>
      &copy; Mike Mirzayanov
    </footer>
  </div>
</template>

<script>
export default {
  name: "Enter",

  props: ['userId', 'roomId', 'users', 'rooms', 'person'],

  data: function () {
    return {
      moveDurationSeconds: 30,
      gameCycleCount: 0,
      dictionaries: "MEDIUM",
      overtime: 5,
      userName: "~",
      error: "",
      usePerson: true,
      useChannelName: true,
      rated: true
    }
  },

  computed: {
    edit: function () {
      return this.roomId && this.rooms && this.roomId in this.rooms && this.rooms[this.roomId].edit;
    },

    room: function () {
      if (this.roomId && this.rooms && this.roomId in this.rooms && this.rooms[this.roomId]) {
        return this.rooms[this.roomId];
      } else {
        return null;
      }
    }
  },
  updated() {
    if (this.person && this.userName === "~") {
      this.userName = this.person.shortName;
    } else if (this.userName === "~") {
      this.userName = "";
    }
  }, created() {
    if (this.person) {
      this.userName = this.person.shortName;
    }
  }, mounted() {
    this.updateFocus();
    if (this.person) {
      this.userName = this.person.shortName;
    }
    const update = () => {
      if (this.edit) {
        const room = this.rooms[this.roomId];
        this.moveDurationSeconds = room.moveDurationSeconds;
        this.gameCycleCount = room.gameCycleCount;
        this.rated = room.rated;
        for (const dictionary of room.dictionaries) {
          this.dictionaries = dictionary;
        }
        this.overtime = room.overtime;
      }
    }
    update();
    if (!this.edit) {
      setTimeout(() => {
        update();
      }, 500);
      setTimeout(() => {
        update();
      }, 1000);
    }
  },

  methods: {
    // onResetRecording: function () {
    //   this.$root.$emit("onServerEventMoveStarted");
    // },

    showAlert: function () {
      alert(this.$refs["ratingNote"].getAttribute("data-text"));
    },

    updateFocusSync: function () {
      if (this.roomId && this.rooms && this.roomId in this.rooms) {
        if (this.$refs.userName) {
          this.$refs.userName.focus();
        }
      } else {
        if (this.$refs.moveDurationSeconds) {
          this.$refs.moveDurationSeconds.focus();
        }
      }
    },

    updateFocus: function () {
      for (const ms of [100, 200, 300, 400, 500, 600, 800, 1000]) {
        setTimeout(() => {
          this.updateFocusSync();
        }, ms);
      }
    },

    insertUser: function () {
      if (!this.userName || this.userName.trim().length === 0) {
        this.error = "Введите имя";
        return;
      }
      if (this.userName.length > 24
          || this.userName.indexOf("&") >= 0
          || this.userName.indexOf("<") >= 0
          || this.userName.indexOf(">") >= 0
          || this.userName.indexOf("\"") >= 0
          || this.userName.indexOf("'") >= 0
          || this.userName.indexOf(";") >= 0) {
        this.error = "Имя слишком длинное или содержит один из служебных символов &<>;\"'";
      } else {
        const noSpacesThisUserName = this.userName.trim().replace(/\s+/g, '');
        for (const uid of this.rooms[this.roomId].userIds) {
          if (uid && this.users[uid]) {
            const u = this.users[uid];
            const noSpacesName = u.name.trim().replace(/\s+/g, '');
            if (noSpacesName === noSpacesThisUserName
                && !(this.person && u.person && this.person.uid === u.person.uid)) {
              this.error = "Имя уже используется";
              return;
            }
          }
        }

        if (this.rooms[this.roomId].preparedUsersOnly && (!this.usePerson || !this.person
            || !this.person.discordId || this.person.discordId.length === 0
            || !this.person.uid || this.person.uid.length === 0)) {
          this.error = "Только участники с hat42-аккаунтом и привязанным Discord-аккаунтом могут зайти в эту комнату";
          return;
        }

        this.error = "";
        this.$root.$emit("insertUser", this.roomId, this.userName, this.usePerson);
      }
    },

    handleRoom: function () {
      console.log("Game: " + JSON.stringify({
        moveDurationSeconds: this.moveDurationSeconds,
        gameCycleCount: this.gameCycleCount,
        rated: this.rated,
        dictionaries: this.dictionaries
      }));

      if ((this.moveDurationSeconds < 20 || this.moveDurationSeconds > 60) && this.rated) {
        alert("Для рейтинговых игр длительность хода должна быть от 20 до 60 секунд");
        return;
      }

      if (this.gameCycleCount < 0 && this.rated) {
        alert("Для рейтинговой игры установите точное количество кругов");
        return;
      }

      if (this.edit) {
        this.$root.$emit('editRoom', this.roomId, this.userId,
            this.moveDurationSeconds, this.gameCycleCount, this.dictionaries, this.overtime, this.useChannelName, this.rated);
      } else {
        this.$root.$emit('insertRoom',
            this.moveDurationSeconds, this.gameCycleCount, this.dictionaries, this.overtime, this.rated);
      }

      this.updateFocus();
    },

    leaveRoom: function () {
      if (confirm("Вы действительно хотите покинуть комнату?")) {
        window.location.href = "/?start=1";
      }
    }
  }
}
</script>

<style scoped>
footer {
  margin-top: 6rem;
}

.welcome {
  position: relative;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--black-color);
  text-align: center;
  display: block;
  padding-top: 8rem;
}

.userName {
  display: block;
  text-align: center;
  margin: 4rem auto;
  height: 6rem;
  font-size: 2.4rem;
  width: 100%;
}

.form {
  font-size: 2.4rem;
  margin-top: 4rem;
}

.buttons {
  margin-top: 3.5rem;
  text-align: center;
}

.create, .leave, .edit {
  margin: 0.5rem auto;
  color: black;
  font-size: 1.8rem;
  text-align: center;
  padding: 1em 2em;
  background-color: var(--success-color);
  border: 0.1rem solid ghostwhite;
  width: min(100%, 30rem);
  cursor: pointer;
}

.leave {
  background-color: var(--minor-color);
}

.error {
  text-align: center;
  color: var(--error-color);
  font-size: 1.8rem;
}

.li {
  margin-top: 0.5rem;
}

.fb {
  position: relative;
}

.fas {
  position: absolute;
  left: 0;
}

.field {
  width: min(70rem, 90%);
  margin: 0 auto 1rem auto;
  font-size: 2rem;
}

.field label span {
  display: inline-block;
  width: min(30rem, 40%);
  text-align: right;
  margin-right: 2rem;
}

@media (max-width: 1024px) {
  .form {
    font-size: 2rem;
  }

  .field {
    font-size: 1.4rem;
  }
}

.moveDurationSecondsLabel {
}

.moveDurationSecondsLabel span {
}

.moveDurationSeconds, .gameCycleCount, .dictionaries {
  width: 24rem !important;
}

.note, .room {
  margin-top: 4rem;
  padding: 1rem;
  font-size: var(--normal-font-size);
  border: var(--border);
  border-radius: var(--border-radius);
  color: var(--muted-color);
}

.use-person {
  display: flow-root;
}

.use-person input {
  position: relative;
  top: 0.2rem;
  margin-right: 0.5rem;
}

.use-person div {
  padding: 1rem;
  float: right;
  border: var(--border);
  font-size: var(--smaller-font-size);
}

.usePersonNote {
  position: absolute;
  top: 16rem;
  right: 2rem;
  color: var(--muted-color);
  font-weight: normal;
}

.aboutChannelNote {
  margin-top: 1rem;
  color: var(--muted-color);
  font-size: var(--smaller-font-size);
  font-weight: bold;
}

.small {
  font-size: var(--smaller-font-size);
  position: relative;
  bottom: 0.25rem;
  color: var(--muted-color);
}

.ratedNote {
  font-size: var(--small-font-size);
  text-align: left !important;
  margin-left: 0.5rem;
  position: relative;
  bottom: 0.25rem;
}

.ratedNote a {
  color: var(--black-color);
}

.ratedNote .i {
  margin: 0;
  padding: 0;
  width: auto;
}

.ratedNote i, .ratedNote svg {
  font-size: var(--normal-font-size) !important;
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
