<template>
  <div class="RoomGameOver">
    <table class="standings">
      <thead>
      <tr>
        <th>№</th>
        <th>Игрок</th>
        <th>Объяснил</th>
        <th>Ответил</th>
        <th>Всего</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in standingsRows" :key="row.user.id">
        <td class="rank">
          {{ row.rank }}
        </td>
        <td class="name">
          {{ row.user.name }}
        </td>
        <td class="explainedPoints">
          {{ row.user.explainedPoints }}
        </td>
        <td class="guessedPoints">
          {{ row.user.guessedPoints }}
        </td>
        <td class="points">
          {{ row.user.explainedPoints + row.user.guessedPoints }}
        </td>
      </tr>
      </tbody>
    </table>

    <div class="note">
      Ходов: {{ room.gameMoveCount }} по {{ room.moveDurationSeconds }} сек.,
      на слово в среднем: {{ avgWordSeconds }} сек.
    </div>

    <table class="gameOverDetails">
      <thead>
      <tr>
        <th>
          Кто/кому
        </th>
        <th class="name" v-for="uid in room.gameOverDetails.playerUserIds" :key='"thead"+uid'>
          {{ users[uid].name.charAt(0) }}
        </th>
        <th>=</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(trUid, i) in room.gameOverDetails.playerUserIds" :key='"tr"+trUid'>
        <td class="name">{{ users[trUid].name }}</td>

        <td v-for="(tdUid, j) in room.gameOverDetails.playerUserIds" :key="'td'+tdUid">
          <div v-if="i !== j">
            <div class="detailsPoints" v-html="cell(room.gameOverDetails.points[i][j])"></div>
            <div class="detailsMovePoints" title="По ходам">
              {{ room.gameOverDetails.movePoints[i][j].join("+") }}
            </div>
          </div>
        </td>
        <td class="detailsPoints" v-html="tr(room.gameOverDetails.points[i].reduce((partialSum, a) => partialSum + a, 0))">
        </td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td class="detailsPoints" v-for="(tdUid, j) in room.gameOverDetails.playerUserIds" :key="'td'+tdUid"
            v-html="tr(room.gameOverDetails.points.map(row => row[j]).reduce((partialSum, a) => partialSum + a, 0))">
        </td>
        <td>&nbsp;</td>
      </tr>
      </tbody>
    </table>

    <div class="buttons">
      <div class="doNotForget">Не забудьте сохранить игру</div>
      <button class="save" title="Сохранить игру"
              @click.prevent="$root.$emit('saveGame')"><i class="fa fa-save"/> Сохранить игру</button>
      <button class="restart" title="Играем ещё"
              @click.prevent="$root.$emit('resetGame')"><i class="fa fa-refresh"/> Играем ещё</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomGameOver",
  props: ['room', 'users', 'userId'],

  computed: {
    avgWordSeconds: function () {
      return this.gamePoints === 0 ? 0 : Math.round(this.room.gameMoveCount * this.room.moveDurationSeconds / this.gamePoints * 100) / 100.0;
    },

    gamePoints: function () {
      return Math.round(this.standingsRows.map(row => row.points).reduce((partialSum, a) => partialSum + a, 0) / 2);
    },

    standingsRows: function () {
      const standings = [];
      let index = 0;
      for (const userId of this.room.userIds) {
        const u = this.users[userId];
        if (!u.guest) {
          standings.push({user: u, points: u.explainedPoints + u.guessedPoints, index: index++});
        }
      }
      standings.sort((a, b) => {
        if (a.points !== b.points) {
          return a.points > b.points ? -1 : 1;
        } else {
          return a.index === b.index ? 0 : (a.index < b.index ? -1 : 1);
        }
      });
      for (let i = 0; i < standings.length; i++) {
        if (i > 0 && standings[i].points === standings[i - 1].points) {
          standings[i].rank = standings[i - 1].rank;
        } else {
          let l = i, r = i;
          while (l > 0 && standings[l - 1].points === standings[i].points) {
            l--;
          }
          while (r + 1 < standings.length && standings[r + 1].points === standings[i].points) {
            r++;
          }
          standings[i].rank = l === r ? (l + 1).toString() : (l + 1).toString() + "-" + (r + 1).toString();
        }
      }
      return standings;
    }
  },

  methods: {
    cell: function (points) {
      if (points !== 0) {
        const perf = Math.round(this.room.moveDurationSeconds * this.room.gameCycleCount
            * 100.0 / points) / 100.0;
        return points + "<small title='в среднем секунд на слово'>:" + perf + "</small>";
      } else {
        return "";
      }
    },
    tr: function (points) {
      if (points !== 0) {
        const perf = Math.round(this.room.moveDurationSeconds * this.room.gameCycleCount
            * (this.room.gameOverDetails.playerUserIds.length - 1) * 100.0 / points) / 100.0;
        return points + "<small title='в среднем секунд на слово'>:" + perf + "</small>";
      } else {
        return "";
      }
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

td {
  text-align: center;
}

table tr {
  border-bottom: var(--border);
}

table td, table th {
  padding: 1rem 1.5rem;
}

.name {
  color: var(--caption-color);
  font-weight: bold;
}

table tbody tr:nth-child(odd) {
  background-color: var(--datatable-dark-cell-color);
}

.note {
  text-align: center;
  margin-top: 1rem;
  font-size: var(--normal-font-size);
  color: var(--muted-color);
}

.gameOverDetails {
  margin-top: 4rem;
}

.detailsPoints {
  color: var(--black-color);
}

.detailsMovePoints {
  font-size: var(--small-font-size);
  color: var(--muted-color);
}

.buttons {
  margin-top: 5rem;
  text-align: center;
}

button {
  margin: 1em auto;
  color: black;
  font-size: 1.8rem;
  text-align: center;
  padding: 1em 2em;
  display: block;
  background-color: var(--minor-color);
  border: 0.1rem solid ghostwhite;
  width: min(100%, 30rem);
  cursor: pointer;
}

button.save {
  background-color: var(--success-color);
}

@media (max-width: 1024px) {
  .standings thead {
    display: none;
  }
}

th, td {
  color: var(--black-color);
}

.doNotForget {
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  background-color: var(--highlight-color);
}
</style>