<template>
  <span>
    <i v-if="person" class="fas fa-user" :title="person.firstName + ' @' + person.nickname + ' ' + person.lastName"></i>
    <span   v-if="person && showName" class="displayedName">{{displayedName}}</span>
    <span   v-if="person && showNickname" class="nickname">{{person.nickname}}</span>
    <span   v-if="name" class="name">{{name}}</span>
  </span>
</template>

<script>
import * as util from "@/util";
import {toShortUserName} from "@/util";

export default {
  name: "Person",
  props: ['person', 'showName', 'showNickname', 'name'],
  methods: {
    toShortUserName(name) {
      return util.toShortUserName(name);
    }
  }, computed: {
    displayedName: function () {
      if (this.person.shortName && this.person.shortName.trim().length > 0) {
        return toShortUserName(this.person.shortName.trim());
      } else {
        return toShortUserName(this.person.nickname);
      }
    }
  }
}
</script>

<style scoped>
.fa-user {
  margin-right: 0.5rem;
}
</style>
