import Vue from 'vue'
import App from './App.vue'

import data from './data';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueMobileDetection from "vue-mobile-detection";

Vue.use(VueMobileDetection);
Vue.config.productionTip = true

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

new Vue({
    data: function () {
        return data;
    },
    render: h => h(App)
}).$mount('#app');
