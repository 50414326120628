<template>
  <div class="popup ComplainWord" v-bind:style="{ display: display ? 'block' : 'none' }">
    <div class="close" @click.prevent="display = false; $root.$emit('setOverlay', false);">&times;</div>

    <div class="subtitle">Пожаловаться на слово (словарь: {{ dictionaryNames }})</div>
    <div class="title">{{ word }}</div>

    <section>
      <div @click.prevent="doComplain(-1)" :class="{option: true, fabox: true, active: active1}"><i
          class="fas fa-arrow-down"></i> <span>Слишком сложное</span></div>
      <div @click.prevent="doComplain(1)" :class="{option: true, fabox: true, active: active2}"><i
          class="fas fa-arrow-up"></i> <span>Слишком простое</span></div>
      <div @click.prevent="doComplain(0)" :class="{option: true, fabox: true, active: active3}"><i
          class="fas fa-trash"></i> <span>Неудачное для Шляпы</span></div>
      <div v-if="direction !== -2" @click.prevent="doComplain(-2)"
           :class="{option: true, fabox: true, active: active4}"><i
          class="fas fa-remove"></i> <span>Удалить жалобу</span></div>
    </section>
  </div>
</template>

<script>
import {toDictionaryName} from "@/util";

export default {
  name: "ComplainWord",
  props: ['room'],
  data: function () {
    return {
      active1: false,
      active2: false,
      active3: false,
      active4: false,
      display: false,
      word: null,
      direction: -2
    }
  }, mounted() {
    this.$root.$on("closePopups", () => this.display = false);
    this.$root.$on("complainWord", (word) => {
      this.clear();
      if (word.indexOf("|") < 0) {
        this.word = word;
        this.direction = -2;
      } else {
        this.word = word.substr(0, word.indexOf("|"));
        this.direction = Number.parseInt(word.substr(word.indexOf("|") + 1));
      }
      if (this.direction === -1) {
        this.active1 = true;
      }
      if (this.direction === 1) {
        this.active2 = true;
      }
      if (this.direction === 0) {
        this.active3 = true;
      }
      this.display = true;
      this.$root.$emit('setOverlay', true);
    });
  }, computed: {
    dictionaryNames: function () {
      if (this.room) {
        return this.room.dictionaries.map(dictionary => toDictionaryName(dictionary)).join(", ");
      } else {
        return "";
      }
    }
  }, methods: {
    clear: function () {
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;
      this.active4 = false;
    },

    doComplain: function (direction) {
      this.clear();

      if (direction === -1) {
        this.active1 = true;
      }
      if (direction === 1) {
        this.active2 = true;
      }
      if (direction === 0) {
        this.active3 = true;
      }
      if (direction === -2) {
        this.active4 = true;
      }

      this.$root.$emit("onComplainWord", this.word, direction);

      window.setTimeout(() => {
        this.clear();
        this.display = false;
        this.$root.$emit('setOverlay', false);
      }, 500);
    }
  }
}
</script>

<style scoped>
.subtitle {
  font-size: var(--larger-font-size);
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
}

.option {
  border: var(--border);
  background-color: var(--datatable-color);
  margin: 2rem 0;
  padding: 1rem 2rem;
  width: 23rem;
  cursor: pointer;
}

.option span {
  display: inline-block;
  margin-left: 2rem;
}

.active {
  background-color: var(--success-color);
}

</style>
