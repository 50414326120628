// noinspection JSIgnoredPromiseFromCall

export const Sounds = {
    preload: () => {
        let volume = localStorage.getItem("volume");
        if (volume == null || volume.length === 0) {
            localStorage.setItem("volume", Sounds.getVolumes()[0][0]);
        }

        Sounds.correctAnswer = new Audio('/assets/audio/correct.mp3');
        Sounds.beforeMoveEnds = new Audio('/assets/audio/sound_count_down.mp3');
        Sounds.violation = new Audio('/assets/audio/sound_count_down.mp3');
        Sounds.moveEnded = new Audio('/assets/audio/stop_signal.mp3');
    },

    getVolumes: () => {
        return [["0", "0", "без звука"],
            ["0.001", "0001", "очень тихо"],
            ["0.005", "0005", "тихо"],
            ["0.02", "002", "нормально"],
            ["0.05", "005", "громко"],
            ["0.2", "02", "очень громко"]];

    },

    getVolume: () => {
        return Number.parseFloat(localStorage.getItem("volume"));
    },

    playCorrectAnswer: () => {
        Sounds.correctAnswer.volume = Sounds.getVolume();
        if (Sounds.moveEnded.volume > 0) {
            Sounds.correctAnswer.play();
        }
    },

    playViolation: () => {
        Sounds.violation.volume = Sounds.getVolume();
        if (Sounds.moveEnded.volume > 0) {
            Sounds.violation.play();
        }
    },

    playBeforeMoveEnds: () => {
        Sounds.beforeMoveEnds.volume = Sounds.getVolume();
        if (Sounds.moveEnded.volume > 0) {
            Sounds.beforeMoveEnds.play();
        }
    },

    playMoveEnded: () => {
        Sounds.moveEnded.volume = Sounds.getVolume();
        if (Sounds.moveEnded.volume > 0) {
            Sounds.moveEnded.play();
        }
    }
};

Sounds.preload();
