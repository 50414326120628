<template>
  <div>
    <ComplainWord :room="room"/>

    <div class="changeCounts popup" v-bind:style="{ display: showChangeCounts ? 'block' : 'none' }">
      <div class="close" @click.prevent="showChangeCounts = false; $root.$emit('setOverlay', false);">&times;</div>

      <div class="title">Введите изменение счёта</div>
      <div class="note">Введите положительное число, если счёт надо увеличить. Введите отрицательное число, если счёт
        надо уменьшить. Нули можно не вводить.
      </div>

      <form @submit.prevent="onChangeCounts()">
        <table class="users">
          <tr v-for="userId in room.userIds" :key="userId" class="user"
              v-bind:class="{ spoken: users[userId].spoken }">
            <td>
              {{ users[userId].name }}
            </td>
            <td>
              (<small>текущий счёт:
              {{ users[userId].explainedCount }}+{{
                users[userId].guessedCount
              }}={{ users[userId].explainedCount + users[userId].guessedCount }}</small>):
            </td>
            <td>
              <label>
                <input v-bind:id="'explainedChangeCounts_input_' + userId" v-model="explainedChangeCounts[userId]"/>
              </label>
            </td>
            <td>
              <label>
                <input v-bind:id="'guessedChangeCounts_input_' + userId" v-model="guessedChangeCounts[userId]"/>
              </label>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <input class="button" type="submit" value="Изменить счёт">
            </td>
          </tr>
        </table>
      </form>
    </div>

    <div :class="{sidebar: true}">
      <div v-if="user && user.hidden" class="hiddenNotice">
        <i class="fa-solid fa-skull"></i>
        Вы больше не отображены в списке игроков комнаты. Если хотите вернуться к игре, то выйдите
        из комнаты и зайдите снова.
      </div>

      <div class="users">
        <RoomUsers :room="room" :room-users="roomUsers" :user="users[userId]" :connected-user-ids="connectedUserIds"/>

        <input type="hidden" class="roomLink" ref="roomLink" v-model="this.roomLink"/>

        <div v-if="(room.move && (userId === room.move.explainingUserId
            || userId === room.move.guessingUserId)) && room.move.secondsLeft >= 0">
          <button v-if="userId === room.move.explainingUserId" class="sidebar-button no-answer"
                  @click.prevent="$root.$emit('onSkip')"><i class="fas fa-bolt"></i> Пропустить
          </button>
          <button class="sidebar-button interrupt-move"
                  @click.prevent="onInterruptMove()"><i class="fas fa-ban"></i> Прервать
          </button>
        </div>

        <div v-else @click.prevent="copyRoomLink" class="copyRoomLink fabox">
          <i class="fas fa-copy"></i>
          Ссылка
        </div>

        <RoomCustomWords :room="room"/>

        <div class="gameStatus" v-if="room.gameMoveCount > 0 && room.completedMoveCount < room.gameMoveCount">
          Ход {{ room.completedMoveCount + 1 }} из {{ room.gameMoveCount }}
        </div>

        <a style="float: right" class="action-a" href="#" title="Редактировать игру"
           @click.prevent="$root.$emit('setRoomEdit', true)"><i class="fa fa-edit"/></a>

        <a style="float: right" class="action-a" href="#" title="Перезапустить игру"
           @click.prevent="$root.$emit('resetGame')"><i class="fa fa-refresh"/></a>

        <a style="float: right" class="action-a" href="#" title="Выйти из комнаты"
           @click.prevent="leaveRoom()"><i class="fa fa-sign-out"/></a>

        <a style="float: right" class="action-a" href="#" title="Пропусить ход"
           @click.prevent="skipMove()"><i class="fa fa-forward"/></a>

        <a style="float: right" class="action-a" href="#" title="Перемешать игроков"
           @click.prevent="onShufflePlayers"><i class="fa fa-shuffle"/></a>
      </div>

      <div class="gameInfo">
        <template v-if="room.gameCycleCount<0">
          без ограничения кругов,
        </template>
        <template v-else>
          {{ room.gameCycleCount }} круг(ов),
        </template>
        {{ room.moveDurationSeconds }} сек. на ход,
        {{ room.dictionaries.join(", ") }},
        <template v-if="room.rated">
          рейт.
        </template>
        <template v-else>
          <span style="color: var(--error-color); font-weight: bold">нерейт.</span>
        </template>
      </div>

      <div class="messages">
        <div v-for="messageId in room.messageIds" :key="messageId">
          <div
              v-if="messageId in messages && (!messages[messageId].exclusionUserId
                || messages[messageId].exclusionUserId !== userId)"
              v-bind:class="[{message: true, moveInfo: messages[messageId].parameterName === 'moveInfo'},
              messages[messageId].type]">
            <a style="margin-left: 0.5rem" v-if="messages[messageId].parameterName === 'moveInfo'
                 && JSON.parse(messages[messageId].parameterValue).cycleId === lastMovesCycleId"
               class="delete-move-a move-a" href="#"
               @click.prevent="$root.$emit('deleteMove', JSON.parse(messages[messageId].parameterValue))">
              <i class="fas fa-close"></i>
            </a>
            <a v-if="messages[messageId].parameterName === 'moveInfo'" class="edit-move-a move-a" href="#"
               @click.prevent="$root.$emit('editMove', JSON.parse(messages[messageId].parameterValue))">
              <i class="fas fa-edit"></i>
            </a>
            <a v-if="messages[messageId].parameterName === 'word' && person" class="complain-word-a move-a" href="#"
               @click.prevent="$root.$emit('complainWord', messages[messageId].parameterValue)">
              <i class="fas fa-exclamation-triangle"></i>
            </a>
            <span class="messageCreationTime">{{ messages[messageId].creationTime }}</span>
            <span class="messageText" v-html="messages[messageId].text"></span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="room.move && room.move.confirm" class="main">
      <RoomConfirm :room="room"/>
      <RoomNextMoves :room="room" :users="users" :user-id="userId"/>
      <RoomPrevMoves :room="room" :users="users" :user-id="userId"/>
    </div>

    <div v-else-if="room.gameOver" class="main">
      <RoomGameOver :room="room" :users="users" :user-id="userId"/>
    </div>

    <div v-else class="main">
      <RoomStatus :room="room" :users="users" :userId="userId" :skip-recently="skipRecently"
                  :correct-answer-recently="correctAnswerRecently"/>

      <div>
        <template v-if="room.move">
          <template v-if="userId === room.move.explainingUserId">
            <button class="action-button" @click.prevent="onCorrectAnswer()"
                    v-text="this.correctAnswerText"></button>
            <button v-if="room.move.secondsLeft <= 0" class="action-button no-answer"
                    @click.prevent="$root.$emit('onNoAnswer')">Нет ответа
            </button>
          </template>
        </template>
        <template v-else-if="!room.move && room.nextPairs.length > 0 && userId === room.nextPairs[0].explainingUserId">
          <button id="startMove" v-bind:class="{'action-button': true, noMovePause: room.noMovePause}"
                  @click.prevent="startMove()">{{ startMoveText }}
          </button>
        </template>

        <button v-if="room.move
              && userId === room.move.guessingUserId
              && room.move.secondsLeft <= 0
              && room.move.timeoutConfirmed
              && room.overtime > 0" class="action-button no-answer"
                @click.prevent="$root.$emit('onNoAnswer')">Нет ответа
        </button>

        <RoomNextMoves :room="room" :users="users" :user-id="userId"/>
        <RoomPrevMoves :room="room" :users="users" :user-id="userId"/>
      </div>
    </div>
  </div>
</template>

<script>
import {Transport} from "@/transport";
import RoomStatus from "@/components/RoomStatus.vue";
import RoomUsers from "@/components/RoomUsers.vue";
import RoomNextMoves from "@/components/RoomNextMoves.vue";
import RoomPrevMoves from "@/components/RoomPrevMoves.vue";
import RoomCustomWords from "@/components/RoomCustomWords.vue";
import RoomConfirm from "@/components/RoomConfirm.vue";
import RoomGameOver from "@/components/RoomGameOver.vue";
import * as util from "@/util";
import ComplainWord from "@/components/ComplainWordPopup.vue";

export default {
  name: "Room",
  components: {
    ComplainWord,
    RoomGameOver, RoomConfirm, RoomCustomWords, RoomNextMoves, RoomPrevMoves, RoomUsers, RoomStatus
  },
  props: ['room', 'users', 'userId', 'messages', 'overlay', 'connectedUserIds', 'person'],
  data: function () {
    return {
      correctAnswerRecently: false,
      skipRecently: false,
      recentButtonPressMillis: 0,
      correctAnswerText: "Правильный ответ!",
      showChangeCounts: false,
      explainedChangeCounts: {},
      guessedChangeCounts: {},
      startMoveText: "Поехали!"
    }
  }, beforeDestroy() {
    const events = [
      "onServerEventCorrectAnswer",
    ];
    events.forEach(e => this.$root.$off(e));
  }, mounted() {
    window.addEventListener('keydown', (e) => {
      if (this.overlay) {
        if (e.key === 'Escape') {
          e.preventDefault();
          return false;
        }
      } else {
        if (this.room && this.room.move && this.room.move.confirm) {
          if (e.key === ' ') {
            e.preventDefault();
            return false;
          } else {
            return true;
          }
        }
        if (e.key === ' ') {
          e.preventDefault();
          return false;
        }
        if (e.key === 'Escape'
            && this.room.move
            && this.userId === this.room.move.explainingUserId) {
          e.preventDefault();
          return false;
        }
      }
      return true;
    });

    window.addEventListener('keyup', (e) => {
      if (this.overlay) {
        if (e.key === 'Escape') {
          this.$root.$emit('closePopups');
          this.$root.$emit('setOverlay', false);
          e.preventDefault();
          return false;
        }
      } else {
        if (this.room && this.room.move && this.room.move.confirm) {
          if (e.key === ' ') {
            e.preventDefault();
            return false;
          } else {
            return true;
          }
        }
        if (e.key === ' ') {
          if (!this.room.move && this.room.nextPairs.length > 0
              && this.userId === this.room.nextPairs[0].explainingUserId) {
            this.startMove();
          } else if (this.room.move && this.userId === this.room.move.explainingUserId) {
            this.onCorrectAnswer();
          } else {
            return true;
          }
          e.preventDefault();
          return false;
        }
        if (e.key === 'Escape'
            && this.room.move
            && this.userId === this.room.move.explainingUserId) {
          if (this.room.move.secondsLeft <= 0) {
            this.$root.$emit('onNoAnswer');
          } else {
            this.$root.$emit('onSkip');
          }
          e.preventDefault();
          return false;
        }
      }
      return true;
    });

    this.$root.$on("onServerEventCorrectAnswer", (move) => {
      if (move.roomId === this.room.id) {
        this.correctAnswerRecently = true;
        setTimeout(() => {
          this.correctAnswerRecently = false
        }, 1000);
      }
    });

    this.$root.$on("onServerEventSkip", (move) => {
      if (move.roomId === this.room.id) {
        this.skipRecently = true;
        setTimeout(() => {
          this.skipRecently = false
        }, 1000);
      }
    });
  }, computed: {
    user: function () {
      if (!this.users || !this.userId) {
        return null;
      } else {
        return this.users[this.userId];
      }
    },

    lastMovesCycleId: function () {
      for (const messageIds of this.room.messageIds) {
        if (this.messages[messageIds].parameterName === "moveInfo") {
          return JSON.parse(this.messages[messageIds].parameterValue).cycleId;
        }
      }
      return null;
    },

    roomLink: function () {
      if (Transport.useHttp()) {
        return "http://" + window.location.hostname + ":8080/?roomId=" + this.room.id;
      } else {
        return "https://" + window.location.hostname + "/?roomId=" + this.room.id;
      }
    },

    thisUserId: function () {
      return this.userId;
    },

    roomUsers: function () {
      const users = [];
      this.room.userIds.forEach(userId => {
        const user = this.users[userId];
        if (!user.guest && !user.hidden) {
          users.push(user);
        }
      });
      this.room.userIds.forEach(userId => {
        const user = this.users[userId];
        if (user.guest && !user.hidden) {
          users.push(user);
        }
      });
      return users;
    }
  },

  methods: {
    toShortUserName(name) {
      return util.toShortUserName(name);
    },

    onShufflePlayers: function (event) {
      this.$root.$emit('shufflePlayers', event.ctrlKey);
    },

    skipMove: function () {
      const move = this.room.move;
      if (!move && this.room.nextPairs.length > 0) {
        const explainingUser = this.users[this.room.nextPairs[0].explainingUserId];
        const guessingUser = this.users[this.room.nextPairs[0].guessingUserId];
        if (explainingUser && guessingUser && confirm("Вы действительно пропустить ход "
            + explainingUser.name + " → " + guessingUser.name + "?")) {
          this.$root.$emit('skipMove', explainingUser, guessingUser);
        }
      }
    },

    leaveRoom: function () {
      if (confirm("Вы действительно хотите покинуть комнату?")) {
        window.location.href = "/?start=1";
      }
    },

    onInterruptMove: function () {
      if (confirm("Вы действительно хотите ПРЕРВАТЬ ход?")) {
        this.$root.$emit('onInterruptMove');
      }
    },

    copyRoomLink: function () {
      let roomLink = document.querySelector('.roomLink');
      roomLink.setAttribute("type", "text");
      roomLink.select()

      let successful;
      try {
        successful = document.execCommand('copy');
      } catch (err) {
        successful = false;
        alert('Oops, unable to copy');
      }

      roomLink.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges()

      if (successful) {
        alert("Ссылка на комнату скопирована в буфер обмена");
      }
    },

    startMove: function () {
      if (this.room.noMovePause) {
        this.startMoveText = "Слишком быстро";
        setTimeout(() => {
          this.startMoveText = "Поехали!";
        }, 1000);
      } else {
        this.recentButtonPressMillis = new Date().getTime();
        this.$root.$emit('startMove');
      }
    },

    onCorrectAnswer: function () {
      const time = new Date().getTime();
      if (time - this.recentButtonPressMillis > 500) {
        this.recentButtonPressMillis = time;
        this.$root.$emit('onCorrectAnswer');
      } else {
        this.correctAnswerText = "Слишком быстро";
        setTimeout(() => {
          this.correctAnswerText = "Правильный ответ!";
        }, 500);
      }
    },

    onChangeCounts: function () {
      const explainedChangeCounts = {...this.explainedChangeCounts};
      const guessedChangeCounts = {...this.guessedChangeCounts};
      let tooHugeChange = false;
      let changed = false;
      let message = "Вы действительно хотите изменить счёт следующим образом?"
      Object.keys(this.users).forEach(userId => {
        const explainedChangeCount = (this.explainedChangeCounts[userId] || 0);
        const guessedChangeCount = (this.guessedChangeCounts[userId] || 0);
        if (explainedChangeCount !== 0 || guessedChangeCount !== 0) {
          changed = true;
          message += "\n    " + this.users[userId].name + ":";
          if (explainedChangeCount !== 0) {
            message += " изменение объяснённых на "
                + (explainedChangeCount > 0 ? "+" + explainedChangeCount : explainedChangeCount === 0 ? 0 : explainedChangeCount);
            if (Math.abs(explainedChangeCount) > 1000) {
              tooHugeChange = true;
            }
          }
          if (guessedChangeCount !== 0) {
            if (explainedChangeCount !== 0) {
              message += ",";
            }
            message += " изменение угаданных на "
                + (guessedChangeCount > 0 ? "+" + guessedChangeCount : guessedChangeCount === 0 ? 0 : guessedChangeCount);
            if (Math.abs(guessedChangeCount) > 1000) {
              tooHugeChange = true;
            }
          }
        }
      })
      if (tooHugeChange) {
        alert("Слишком значительное изменение счёта");
        return;
      }
      if (changed && confirm(message)) {
        this.$root.$emit('changeCounts', explainedChangeCounts, guessedChangeCounts);
        Object.keys(this.users).forEach(userId => {
          this.$delete(this.explainedChangeCounts, userId);
          this.$delete(this.guessedChangeCounts, userId);
        });
        this.showChangeCounts = false;
        this.$root.$emit('setOverlay', false);
      } else {
        alert("Нет данных для сохранения")
      }
    }
  }
}
</script>

<style scoped>
.changeCounts .users {
  margin-left: 0;
}

.changeCounts .user {
  font-weight: bold;
  color: var(--caption-color);
  font-size: 1.8rem;
}

.changeCounts .user input {
  width: 6rem;
  font-size: 1.8rem;
  padding: 2px;
  margin: 2px 2px 2px 2rem;
}

button:focus {
  outline: 0;
}

.welcome {
  font-weight: bold;
}

.copyRoomLink {
  border-radius: var(--border-radius);
  border: 0.1rem solid var(--border-color);
  color: var(--caption-color);
  cursor: pointer;
  font-size: 1.4rem;
  margin: 0 0 1rem 0;
  padding: 0.75em;
  position: relative;
  text-align: center;
}

.main {
  margin-right: calc(min(30rem, 35%) + 2rem) !important;
}

.sidebar {
  float: right;
  width: min(30rem, 35%);
}

.sidebar .users {
  display: flow-root;
}

.sidebar .hiddenNotice {
  border: var(--border);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--negative-color);
  color: var(--white-color);

}

.sidebar .hiddenNotice i,
.sidebar .hiddenNotice svg {
  font-size: var(--largest-font-size);
  float: left;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.messages {
  margin-top: 8rem;
}

.message {
  border-radius: 4px;
  border: var(--border);
  padding: 0.25em;
  margin: 0.5em 0;
  font-size: 1.2rem;
}

.messageCreationTime {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--muted-color);
  /*display: inline-block;*/
  margin: 0.1em 1em 0.1em 0.1em;
}

.messageText {
  font-size: 1.2rem;
  color: var(--black-color);
  /*display: inline-block;*/
}

.action-user-button {
  width: 25%;
  border-radius: var(--border-radius);
  border: var(--border);
  color: var(--caption-color);
  cursor: pointer;
  font-size: 1.6rem;
}

.action-button {
  margin: 1em auto;
  color: black;
  font-size: 1.8rem;
  text-align: center;
  padding: 1em 2em;
  display: block;
  background-color: var(--success-color);
  border: 0.1rem solid ghostwhite;
  width: min(100%, 30rem);
  cursor: pointer;
}

.no-answer {
  background-color: var(--minor-color);
}

.interrupt-move {
  background-color: #EF9A9A;
}

.sidebar-button {
  border: 0.1rem solid ghostwhite;
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  padding: 0.75em;
  position: relative;
  text-align: center;
  width: 50%;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .sidebar-button {
    font-size: 1.1rem;
  }
}

@media (max-width: 800px) {
  .sidebar-button {
    font-size: 1rem;
  }
}

.action-a {
  font-size: 1.4rem;
  margin-left: 1em;
  color: var(--muted-color);
}

.action-a:hover {
  font-weight: bold;
  color: var(--negative-color);
}

.explainedGuessedCount {
  font-size: 2.4rem;
}

.violatedCount {
  margin-top: 0.5em;
  font-size: 1.8rem;
  color: var(--muted-color);
}

.guest {
  font-size: 1.1rem;
  color: var(--muted-color);
}

.MOVE_ODD {
  background-color: var(--odd-move-color);
}

.MOVE_EVEN {
  background-color: var(--highlight-color);
}

.INFO {
  background-color: var(--datatable-color);
}

.INFO .messageText {
  font-weight: bold;
  color: var(--caption-color) !important;
}

.move-a {
  font-size: var(--small-font-size);
  float: right;
  text-decoration: none;
  font-weight: bold;
  color: var(--caption-color);
}

.delete-move-a {
}

.edit-move-a {
}

.moveInfo .messageText {
  font-weight: bold;
}

.gameStatus {
  color: var(--muted-color);
  font-size: var(--smaller-font-size);
  float: left;
  background-color: var(--highlight-color);
  padding: 0.25rem;
}

.noMovePause {
  color: var(--muted-color) !important;
}

.gameInfo {
  margin-top: 1rem;
  color: var(--muted-color);
  font-size: var(--smaller-font-size);
  background-color: var(--highlight-color);
}

</style>
