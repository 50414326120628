function getTimestamp() {
    const now = new Date();

    // Get year, month, day
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    // Get hours, minutes, and seconds
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const SpeechRecognizer = {
    onStart: (startHandler) => SpeechRecognizer.startHandler = startHandler,
    onSpeechStart: (speechStartHandler) => SpeechRecognizer.speechStartHandler = speechStartHandler,
    onError: (errorHandler) => SpeechRecognizer.errorHandler = errorHandler,
    onEnd: (endHandler) => SpeechRecognizer.endHandler = endHandler,
    onWord: (wordHandler) => SpeechRecognizer.wordHandler = wordHandler,
    stopped: false,
    recognition: null,
    lang: null,
    lastOnResultTimeMillis: 0,

    RECORDING_TIME_SLICE: 250,
    RECORDING_MAX_SIZE: 60 * 1000 / 250,
    recording: false,
    mediaRecorder: null,
    recordedChunks: [],
    recordedHistory: [],
    microphoneInterval: null,
    microphoneIntervalId: 0,
    firstChunk: null,

    stop: () => {
        SpeechRecognizer.stopped = true;
        if (SpeechRecognizer.recognition) {
            console.log("SpeechRecognizer: " + getTimestamp() + ": SpeechRecognizer.recognition.abort()/SpeechRecognizer.recognition.stop().");
            SpeechRecognizer.recognition.abort();
            SpeechRecognizer.recognition.stop();
            console.log("SpeechRecognizer: " + getTimestamp() + ": stoppped.");
        }
    },

    newMediaRecorderDelayed: (stream) => {
        setTimeout(() => {
            SpeechRecognizer.newMediaRecorder(stream);
        }, 1000);
    },

    newMediaRecorder: (stream) => {
        SpeechRecognizer.mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/webm; codecs=opus'});
        console.log("Recording: SpeechRecognizer.mediaRecorder=" + SpeechRecognizer.mediaRecorder);

        SpeechRecognizer.mediaRecorder.ondataavailable = event => {
            if (!event || !event.data || event.data.size === 0) {
                console.error("Recording: !event || !event.data || event.data.size === 0");
                return;
            }

            const microphone = document.getElementById("microphone");
            if (!microphone) {
                console.error("Recording: !microphone");
                return;
            }

            if (SpeechRecognizer.recordedChunks.length < SpeechRecognizer.RECORDING_MAX_SIZE) {
                if (!SpeechRecognizer.firstChunk) {
                    SpeechRecognizer.firstChunk = event.data;
                    SpeechRecognizer.recordedHistory.push([getTimestamp(), SpeechRecognizer.recordedChunks]);
                }

                SpeechRecognizer.recordedChunks.push(event.data);

                if (SpeechRecognizer.microphoneInterval) {
                    clearTimeout(SpeechRecognizer.microphoneInterval);
                }
                microphone.style.color = "green";
                microphone.parentNode.title = "Ведётся запись ("
                    + SpeechRecognizer.recordedChunks.length + ")";
                SpeechRecognizer.microphoneIntervalId++;
                const microphoneIntervalId = SpeechRecognizer.microphoneIntervalId;
                SpeechRecognizer.microphoneInterval = setTimeout(() => {
                    if (microphoneIntervalId === SpeechRecognizer.microphoneIntervalId) {
                        microphone.style.color = "";
                        microphone.parentNode.title = "Микрофон неактивен, запись остановлена";
                    }
                }, Math.max(1000, SpeechRecognizer.RECORDING_TIME_SLICE * 2));
            } else {
                if (SpeechRecognizer.microphoneInterval) {
                    clearTimeout(SpeechRecognizer.microphoneInterval);
                }
                microphone.style.color = "orange";
                microphone.parentNode.title = "Запись приостановлена в ожидании хода ("
                    + SpeechRecognizer.recordedChunks.length + ")";
                SpeechRecognizer.microphoneIntervalId++;
                const microphoneIntervalId = SpeechRecognizer.microphoneIntervalId;
                SpeechRecognizer.microphoneInterval = setTimeout(() => {
                    if (microphoneIntervalId === SpeechRecognizer.microphoneIntervalId) {
                        microphone.style.color = "";
                        microphone.parentNode.title = "Микрофон неактивен, запись остановлена";
                    }
                }, Math.max(1000, SpeechRecognizer.RECORDING_TIME_SLICE * 2));
            }
        };

        SpeechRecognizer.mediaRecorder.onerror = function (event) {
            console.error("Recording: MediaRecorder onerror: ", event);
            //SpeechRecognizer.newMediaRecorderDelayed(stream);
        };
        SpeechRecognizer.mediaRecorder.addEventListener('error', () => {
            console.error("Recording: MediaRecorder error.");
            SpeechRecognizer.newMediaRecorderDelayed(stream);
        });

        SpeechRecognizer.mediaRecorder.onstop = (event) => {
            console.error("Recording: MediaRecorder.onstop: ", event);
            SpeechRecognizer.newMediaRecorderDelayed(stream);
        }
        SpeechRecognizer.mediaRecorder.addEventListener('stop', () => {
            console.error("Recording: MediaRecorder stop.");
            SpeechRecognizer.newMediaRecorderDelayed(stream);
        });

        SpeechRecognizer.mediaRecorder.pause = function (event) {
            console.error("Recording: MediaRecorder pause: ", event);
            setTimeout(() => {
                SpeechRecognizer.mediaRecorder.start(SpeechRecognizer.RECORDING_TIME_SLICE);
            }, 1000);
        };
        SpeechRecognizer.mediaRecorder.addEventListener('pause', () => {
            console.error("Recording: MediaRecorder pause.");
            setTimeout(() => {
                SpeechRecognizer.mediaRecorder.start(SpeechRecognizer.RECORDING_TIME_SLICE);
            }, 1000);
        });

        SpeechRecognizer.recordedChunks = [];
        SpeechRecognizer.mediaRecorder.start(SpeechRecognizer.RECORDING_TIME_SLICE);

        console.log("Recording: SpeechRecognizer.mediaRecorder.start()");
    },

    startRecording: () => {
        navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
            console.log("Recording: getUserMedia.then(stream=" + stream + ", " + stream.active + ")");
            SpeechRecognizer.newMediaRecorder(stream);
        })
    },

    provideRecordings: (callback) => {
        let audioContext = new AudioContext();

        function getDuration(srcUrl) {
            return new Promise((resolve) => {
                const audio = new Audio();
                audio.onloadedmetadata = function () {
                    if (audio.duration && audio.duration >= 2) {
                        resolve(audio.duration);
                    }
                };
                audio.src = srcUrl;
            });
        }

        function convertWebmToWavUrl(blob, callback) {
            let fileReader = new FileReader();

            fileReader.onload = function (event) {
                audioContext.decodeAudioData(event.target.result, function (buffer) {
                    const wav = bufferToWave(buffer, buffer.length);
                    let blob = new Blob([new DataView(wav)], {type: 'audio/wav'});
                    const href = URL.createObjectURL(blob);
                    getDuration(href)
                        .then(duration => {
                            callback(href, duration);
                        })
                });
            };

            fileReader.readAsArrayBuffer(blob);
        }

        function bufferToWave(buffer, len) {
            let numOfChan = buffer.numberOfChannels;
            let length = len * numOfChan * 2 + 44;
            let bufferArray = new ArrayBuffer(length);
            let view = new DataView(bufferArray);
            let channels = [];
            let i;
            let sample;
            let offset = 0;
            let pos = 0;

            // write WAVE header
            setUint32(0x46464952);                         // "RIFF"
            setUint32(length - 8);                         // file length - 8
            setUint32(0x45564157);                         // "WAVE"
            setUint32(0x20746d66);                         // "fmt " chunk
            setUint32(16);                                 // length = 16
            setUint16(1);                                  // PCM (uncompressed)
            setUint16(numOfChan);
            setUint32(buffer.sampleRate);
            setUint32(buffer.sampleRate * 2 * numOfChan);  // avg. bytes/sec
            setUint16(numOfChan * 2);                      // block-align
            setUint16(16);                                 // 16-bit (hardcoded in this demo)
            setUint32(0x61746164);                         // "data" - chunk
            setUint32(length - pos - 4);                   // chunk length

            // write interleaved data
            for (i = 0; i < buffer.numberOfChannels; i++) {
                channels.push(buffer.getChannelData(i));
            }

            while (pos < length) {
                for (i = 0; i < numOfChan; i++) {
                    // interleave channels
                    sample = Math.max(-1, Math.min(1, channels[i][offset]));  // clamp
                    sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;  // scale to 16-bit signed int
                    view.setInt16(pos, sample, true);  // write 16-bit sample
                    pos += 2;
                }
                offset++  // next source sample
            }

            // create Blob
            return bufferArray;

            function setUint16(data) {
                view.setUint16(pos, data, true);
                pos += 2;
            }

            function setUint32(data) {
                view.setUint32(pos, data, true);
                pos += 4;
            }
        }

        for (const [index, history] of SpeechRecognizer.recordedHistory.entries()) {
            const [timestamp, chunks] = history;
            console.log("Recording: Playing history " + index + " at " + timestamp + " with " + chunks.length + " chunks");

            const blob = new Blob(chunks, {
                type: 'audio/webm; codecs=opus'
            });

            convertWebmToWavUrl(blob, (href, duration) => {
                callback({timestamp, href, duration});
            });
        }
    },

    initRecording: () => {
        if (SpeechRecognizer.recording) {
            return;
        }

        SpeechRecognizer.recording = true;
        SpeechRecognizer.startRecording();
    },

    resetRecording: () => {
        console.log("Recording: resetRecording");

        if (SpeechRecognizer.recordedHistory.length > 0) {
            SpeechRecognizer.recordedHistory[0][1] = Array.from(SpeechRecognizer.recordedChunks);
        }

        SpeechRecognizer.recordedChunks = [];
        SpeechRecognizer.recordedChunks.push(SpeechRecognizer.firstChunk);
        SpeechRecognizer.recordedHistory.unshift([getTimestamp(), SpeechRecognizer.recordedChunks]);
        SpeechRecognizer.recordedHistory = SpeechRecognizer.recordedHistory.slice(0, 10);
    },

    getRecordingCount: () => {
        return SpeechRecognizer.recordedHistory.length;
    },

    start: () => {
        setTimeout(() => {
            SpeechRecognizer.initRecording(false);
        }, 1000);

        const SpeechRecognition = window.SpeechRecognition ||
            window.webkitSpeechRecognition ||
            window.mozSpeechRecognition ||
            window.msSpeechRecognition ||
            window.oSpeechRecognition;

        if (!SpeechRecognition) {
            const error = "SpeechRecognizer: " + getTimestamp() + ": Невозможно инициализировать распознование речи. Пожалуйста, попробуйте использовать Google Chrome.";
            if (SpeechRecognizer.errorHandler) {
                SpeechRecognizer.errorHandler(error);
            }
            console.log(error);
        } else {
            let recognition = SpeechRecognizer.recognition;
            if (recognition) {
                console.log("SpeechRecognizer: " + getTimestamp() + ": reuse recognition=" + recognition);
            } else {
                recognition = new SpeechRecognition();
                console.log("SpeechRecognizer: " + getTimestamp() + ": create recognition=" + recognition);
                recognition.continuous = true;
                recognition.interimResults = true;
                recognition.maxAlternatives = 3;
                SpeechRecognizer.lang = "ru-RU";
                recognition.lang = SpeechRecognizer.lang;

                setInterval(() => {
                    try {
                        console.log("SpeechRecognizer: " + getTimestamp() + ": SpeechRecognizer.stopped=" + SpeechRecognizer.stopped);
                        if (!SpeechRecognizer.stopped && Date.now() - SpeechRecognizer.lastOnResultTimeMillis >= 5000) {
                            console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.stopping [delay="
                                 + (Date.now() - SpeechRecognizer.lastOnResultTimeMillis) + "].");
                            recognition.abort();
                            recognition.stop();
                            console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.stopped, restarting it");
                            setTimeout(() => recognition.start(), 500);
                        }
                    } catch (e) {
                        // No operations.
                    }
                }, 60000);
            }

            recognition.onstart = () => {
                console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.onstart");
                if (SpeechRecognizer.startHandler) {
                    SpeechRecognizer.startHandler();
                }
            };

            recognition.onsoundstart = () => {
                console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.onsoundstart");
                if (SpeechRecognizer.speechStartHandler) {
                    SpeechRecognizer.speechStartHandler();
                }
            };

            recognition.addEventListener('speechstart', () => {
                console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.speechstart");
            });

            recognition.onerror = (event) => {
                console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.onerror: "
                    + JSON.stringify(event) + " (" + event.error + ").", event);

                const error = "Speech recognition error detected: " + event.error + ".";
                if (SpeechRecognizer.errorHandler) {
                    SpeechRecognizer.errorHandler(error);
                }

                // recognition.abort();
                // recognition.stop();
                // console.log("recognition.stopped");

                // if (!SpeechRecognizer.stopped) {
                //     console.log("recognition.restarting [after error]...: " + new Date());
                //     setTimeout(() => {
                //         console.log("recognition.restart [after error]: " + new Date());
                //         recognition.start();
                //     }, 500);
                // }
            };

            recognition.onend = () => {
                console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.onend");

                // recognition.abort();
                // recognition.stop();
                // console.log("recognition.stopped");

                if (SpeechRecognizer.endHandler) {
                    SpeechRecognizer.endHandler();
                }
                recognition.lang = SpeechRecognizer.lang;

                // if (!SpeechRecognizer.stopped) {
                //     console.log("recognition.restarting...: " + new Date() + ", recognition=" + recognition);
                //     setTimeout(() => {
                //         console.log("recognition.restart: " + new Date() + ", recognition=" + recognition);
                //         recognition.start();
                //     }, 500);
                // }
            }

            recognition.onresult = (event) => {
                SpeechRecognizer.lastOnResultTimeMillis = Date.now();

                let result = '';
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    result += event.results[i][0].transcript;
                }

                console.log("SpeechRecognizer: " + getTimestamp() + ": recognition.onresult " + JSON.stringify(event) + ": `" + result + "`.");

                result.split(/(\s+)/).forEach(item => {
                    const word = item.trim();
                    if (word.length > 0) {
                        if (SpeechRecognizer.wordHandler) {
                            // console.log("recognition: " + word);
                            SpeechRecognizer.wordHandler(word);
                        }
                    }
                })
            };

            SpeechRecognizer.recognition = recognition;

            console.log("recognition.start();");
            SpeechRecognition.stopped = false;
            recognition.start();

            // if (window.addEventListener) {
            //     window.addEventListener("focus", function (event) {
            //         console.log("tab.focus: "+ event);
            //         if (recognition) {
            //             console.log("recognition.stopping... [focus]");
            //             recognition.abort();
            //             recognition.stop();
            //             console.log("recognition.stopped [focus]");
            //             setTimeout(() => recognition.start(), 500);
            //         }
            //     }, false);
            //     window.addEventListener("blur", function (event) {
            //         console.log("tab.blue: "+ event);
            //         if (recognition) {
            //             console.log("recognition.stopping... [blur]");
            //             recognition.abort();
            //             recognition.stop();
            //             console.log("recognition.stopped [blur]");
            //         }
            //     }, false);
            // }
        }
    },

    setLang: (lang) => {
        if (SpeechRecognizer.recognition && (!SpeechRecognizer.lang || SpeechRecognizer.lang !== lang)) {
            console.log("recognition.setLang('" + lang + "')");
            SpeechRecognizer.lang = lang;
            SpeechRecognizer.recognition.stop();
        }
    }
};
